import { Button } from 'primereact/button';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { isValidEmail } from '../../utils/Utils';
import { classNames } from 'primereact/utils';

export default function ContactInput({ onBackPress, onNext, contactInfo }) {
    const [phoneNumber, setPhoneNumber] = useState(contactInfo?.phoneNumber);

    const [email, setEmail] = useState(contactInfo?.email);
    const [submitted, setSubmitted] = useState(false);

    const validateContact = (contact) => {
        const pattern = /^\d{12}$/;
        return pattern.test(contact);
    };

    const onClick = () => {
        setSubmitted(true);
        let isValidForm = true;
        let data = { phoneNumber, email };

        if (!validateContact(phoneNumber)) {
            isValidForm = false;
        }
        if (!isValidEmail(email)) {
            isValidForm = false;
        }

        if (isValidForm) {
            onNext(data);
        }
    };

    return (
        <>
            <div className="words-div">
                <h1>Primary phone number and Email</h1>
                <p>For verification and authentication.</p>
            </div>
            <div className="inputs-div">
                <div className="input-div">
                    <label>Email</label>
                    <input
                        onFocus={() => setSubmitted(false)}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        type="email"
                        placeholder="Email"
                        //className={isInputValid1 ? '' : 'error-input'}
                        className={classNames({
                            'error-input': submitted === true && !isValidEmail(email)
                        })}
                    />
                    {submitted && !isValidEmail(email) && <label id="error">Invalid email address</label>}
                </div>
                <div className="input-div">
                    <label>Phone Number</label>
                    <PhoneInput
                        enableSearch
                        className={classNames({
                            'country-code error-Input': submitted && !validateContact(phoneNumber),
                            'country-code': true
                        })}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e)}
                        country={'ug'}
                        inputProps={{
                            required: true
                        }}
                        onFocus={() => setSubmitted(false)}
                    />

                    {submitted && !validateContact(phoneNumber) && <label id="error">Invalid phone number</label>}
                </div>
                <div>
                    <Button onClick={onBackPress} className="cancel">
                        Back
                    </Button>
                    <Button onClick={onClick} className="send">
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
}
