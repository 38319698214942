import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, hasNull, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import StatusSummary from '../../components/StatusSummary';
import PhoneInput from 'react-phone-input-2';

const Leads = () => {
    let emptyLead = {
        statusID: null,
        stageID: null,
        shopName: null,
        locationLatitude: null,
        locationLongitude: null,
        physicalAddress: null,
        firstName: null,
        phone: null,
        lastName: null,
        source: null,
        email: null,
        remark: null
    };

    const [leads, setLeads] = useState(null);
    const [leadDialog, setLeadDialog] = useState(false);
    const [deleteLeadDialog, setDeleteLeadDialog] = useState(false);
    const [lead, setLead] = useState(emptyLead);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [stage, setStage] = useState(null);
    const [status, setStatus] = useState(null);
    const [source, setSource] = useState(null);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchLeads = async () => {
        setIsLoading(true);
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/leads')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setLeads(response.records);
                setIsLoading(false);
            })
            .catch((error) => {});
    };

    const Stages = [
        { id: 1, name: 'Database' },
        { id: 2, name: 'Contact Established' },
        { id: 3, name: 'Needs Identification' },
        { id: 4, name: 'Proposal Presented' },
        { id: 5, name: 'Negotiation' },
        { id: 6, name: 'Won' },
        { id: 7, name: 'Lost' },
        { id: 8, name: 'On Hold' }
    ];

    const Status = [
        { id: 1, name: 'Hot' },
        { id: 2, name: 'Warm' },
        { id: 3, name: 'Cold' }
    ];

    const Source = [
        // { id: 1, name: 'Referral' },
        // { id: 2, name: 'Email' },
        // { id: 3, name: 'Telephone' },
        // { id: 4, name: 'Website' },
        // { id: 5, name: 'Social Partnership' },
        // { id: 6, name: 'Exhibition' },
        // { id: 7, name: 'Offline Marketing' },
        // { id: 8, name: 'Other' },
        // { id: 9, name: 'Unknown' }

        { name: 'Referral' },
        { name: 'Email' },
        { name: 'Telephone' },
        { name: 'Website' },
        { name: 'Social Partnership' },
        { name: 'Exhibition' },
        { name: 'Offline Marketing' },
        { name: 'Other' },
        { name: 'Unknown' }
    ];

    useEffect(() => {
        fetchLeads();
    }, []);

    const openNew = () => {
        setLead(emptyLead);
        setSubmitted(false);
        setLeadDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setLeadDialog(false);
        clearForm();
    };

    const hideShopDialog = () => {
        setDeleteLeadDialog(false);
    };

    const onStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const onSourceChange = (e) => {
        setSource(e.target.value);
    };

    const onStageChange = (e) => {
        setStage(e.target.value);
    };

    const clearForm = () => {
        setLead(emptyLead);
        setSource(null);
        setStage(null);
        setStatus(null);
        setLoading(false);
    };

    const saveLead = async () => {
        setSubmitted(true);
        const apiUrl = edit && edit === true ? '/leads/' + lead.id : '/leads';

        const payload = {
            statusID: status.id,
            stageID: stage.id,
            shopName: lead.shopName,
            firstName: lead.firstName,
            lastName: lead.lastName,
            phone: phoneNumber,
            email: lead.email,
            locationLatitude: lead.locationLatitude,
            locationLongitude: lead.locationLongitude,
            physicalAddress: lead.physicalAddress,
            source: source.name,
            remark: lead.remark
        };
        console.log(payload);
        if (hasNull(payload) === false) {
            setLoading(true);
            await new BaseApiService(apiUrl)
                .saveRequestWithJsonResponse(payload, edit)
                .then((response) => {
                    setLoading(false);
                    fetchLeads();
                    hideDialog();
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Shop Details saved',
                        life: 3000
                    });
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error.message);
                    setLoading(false);
                });
        }
    };

    const editLead = (lead) => {
        setEdit(true);
        console.log(lead);
        setLead({ ...lead });
        setStatus({ id: lead.statusID, name: lead.statusName });
        setStage({ id: lead.stageID, name: lead.stageName });
        setSource({ name: lead.source });
        setPhoneNumber(lead.phone);
        setLeadDialog(true);
    };

    const confirmDeleteLead = (shop) => {
        setLead(shop);
        setDeleteLeadDialog(true);
    };

    const deleteLead = () => {
        new BaseApiService('/leads/' + lead.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _leads = leads.filter((val) => val.id !== lead.id);
                setLeads(_leads);
                setDeleteLeadDialog(false);
                setLead(emptyLead);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Shop Deleted',
                    life: 3000
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _shop = { ...lead };
        _shop[`${name}`] = val;

        setLead(_shop);
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop name</span>
                {rowData.shopName}
            </>
        );
    };

    const locationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop location</span>
                {rowData.physicalAddress}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phone}
            </>
        );
    };

    const ownerDetailsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Owner</span>
                {rowData.firstName + ' ' + rowData.lastName}
            </>
        );
    };

    const stageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Stage</span>
                {rowData.stageName}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.statusName === 'Hot' && <span className=" bg-orange-100 text-orange-700 p-2 round font-medium">{rowData.statusName}</span>}
                {rowData.statusName === 'Warm' && <span className=" bg-yellow-100 text-yellow-700 p-2 round font-medium">{rowData.statusName}</span>}
                {rowData.statusName === 'Cold' && <span className=" bg-cyan-100 text-cyan-700 p-2 round font-medium">{rowData.statusName}</span>}
            </>
        );
    };

    const sourceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Source</span>
                {rowData.source}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center justify-content-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success " onClick={() => editLead(rowData)} />
                <Button icon="pi pi-map-marker" className=" p-button-rounded p-button-text p-button-success" />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteLead(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Leads</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchLeads} />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            </div>
        </div>
    );

    const allowExpansion = (rowData) => {
        return rowData !== null && rowData !== undefined;
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="field ml-6 pl-4">
                <div className="grid col-12">
                    <div className="col-12 md:col-6 lg:col-3">
                        <label className="mt-3">
                            <span className="font-medium">Owner's Name: </span>
                            <br /> {rowData.shopName}
                        </label>
                    </div>

                    <div className="col-12 md:col-6 lg:col-3">
                        <label className="mt-3">
                            <span className="font-medium"> Email: </span>
                            <br /> <>{rowData.email}</>
                        </label>
                    </div>

                    <div className="col-12 md:col-6 lg:col-3">
                        <label className="mt-3">
                            <span className="font-medium"> Remark: </span>
                            <br /> <>{rowData?.remark}</>
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const leadDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" loading={loading} className="primary-btn" onClick={saveLead} />
        </>
    );

    const deleteLeadDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteLead} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    style={{
                        color: 'var(--text-color)',
                        userSelect: 'none',
                        width: 'auto',
                        textAlign: 'center'
                    }}
                >
                    Showing {options.first} to {options.last} of {options.totalRecords} leads
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Attendants" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Removed Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={leads}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        globalFilter={globalFilter}
                        emptyMessage="No shop found."
                        onRowToggle={(e) => {
                            setExpandedRows(e.data);
                        }}
                        expandedRows={expandedRows}
                        rowExpansionTemplate={rowExpansionTemplate}
                        header={header}
                    >
                        <Column expander={allowExpansion} style={{ width: '5rem' }} />
                        <Column field="name" header="Name" body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '13rem' }} />
                        <Column field="phone" header="Phone" body={phoneNumberBodyTemplate} headerStyle={{ width: '15%', minWidth: '8rem' }} />
                        <Column field="leadStage" header="Stage" body={stageBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column field="source" header="Source" body={sourceBodyTemplate} headerStyle={{ width: '20%', minWidth: '8rem' }} />
                        <Column field="physicalLocation" header="Physical location" body={locationBodyTemplate} headerStyle={{ width: '25%', minWidth: '13rem' }} />
                        <Column
                            field="status"
                            header="Status"
                            body={statusBodyTemplate}
                            headerStyle={{
                                width: '20%',
                                minWidth: '10rem',
                                paddingLeft: '1.8rem'
                            }}
                        />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: 'center' }} />
                    </DataTable>

                    <Dialog visible={leadDialog} style={{ width: '700px' }} header="Lead Details" modal className="p-fluid" footer={leadDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        {/* {isAdmin && (
                            <div className="field" style={{ marginTop: '10px' }}>
                                <label htmlFor="shopOwner">Shop Owner</label>
                                <Dropdown id="shopOwner" value={selectedShopOwner} options={shopOwners} onChange={onShopOwnerChange} optionLabel={(option) => option.firstName + ' ' + option.lastName} filter placeholder="Select a Shop owner" />
                                {submitted && !selectedShopOwner && <small className="p-invalid">Shop owner is required.</small>}
                            </div>
                        )} */}

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Shop Name</label>
                                <InputText
                                    id="name"
                                    value={lead.shopName || ''}
                                    onChange={(e) => onInputChange(e, 'shopName')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.shopName
                                    })}
                                />
                                {submitted && !lead.shopName && <small className="p-invalid">Shop name is required.</small>}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="firstName">First Name</label>
                                <InputText
                                    id="firstName"
                                    value={lead.firstName || ''}
                                    onChange={(e) => onInputChange(e, 'firstName')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.firstName
                                    })}
                                />
                                {submitted && !lead.firstName && <small className="p-invalid">First Name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="lastName">Last Name</label>
                                <InputText
                                    id="lastName"
                                    value={lead.lastName || ''}
                                    onChange={(e) => onInputChange(e, 'lastName')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.lastName
                                    })}
                                />
                                {submitted && !lead.lastName && <small className="p-invalid">Last Name is required.</small>}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="phone">Phone Number</label>
                                <PhoneInput
                                    id="phone"
                                    className={classNames({ 'country-code-reduced': true })}
                                    value={phoneNumber || ''}
                                    onChange={(e) => setPhoneNumber(e)}
                                    country={'ug'}
                                    enableSearch
                                    inputProps={{
                                        required: true
                                    }}
                                />
                            </div>
                            <div className="field col">
                                <label htmlFor="name">Alternative Phone Number</label>
                                <PhoneInput
                                    id="phone"
                                    className={classNames({ 'country-code-reduced': true })}
                                    value={'' || ''}
                                    country={'ug'}
                                    enableSearch
                                    inputProps={{
                                        required: true
                                    }}
                                />
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="email">Email Address</label>
                                <InputText
                                    id="email"
                                    value={lead.email || ''}
                                    onChange={(e) => onInputChange(e, 'email')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.email
                                    })}
                                />
                                {submitted && !lead.email && <small className="p-invalid">Email is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="physicalAddress">Physical address</label>
                                <InputText
                                    id="physicalAddress"
                                    value={lead.physicalAddress || ''}
                                    onChange={(e) => onInputChange(e, 'physicalAddress')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.physicalAddress
                                    })}
                                />
                                {submitted && !lead.physicalAddress && <small className="p-invalid">Physical location name is required.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="locationLatitude">Location latitude</label>
                                <InputText
                                    id="locationLatitude"
                                    value={lead.locationLatitude || ''}
                                    onChange={(e) => onInputChange(e, 'locationLatitude')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.locationLatitude
                                    })}
                                />
                                {submitted && !lead.locationLatitude && <small className="p-invalid">Location latitude is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="locationLongitude">Location longitude</label>
                                <InputText
                                    id="locationLongitude"
                                    value={lead.locationLongitude || ''}
                                    onChange={(e) => onInputChange(e, 'locationLongitude')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.locationLongitude
                                    })}
                                />
                                {submitted && !lead.locationLongitude && <small className="p-invalid">Location longitude is required.</small>}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="locationLatitude">Lead Stage</label>
                                <Dropdown
                                    options={Stages}
                                    value={stage}
                                    optionLabel="name"
                                    onChange={onStageChange}
                                    placeholder="Select a Stage"
                                    className={classNames({ 'p-invalid': submitted && !stage })}
                                    checkmark={true}
                                    highlightOnSelect={false}
                                    filter
                                />
                                {submitted && !lead.locationLatitude && <small className="p-invalid">Default currency is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="statusID">Lead Status</label>
                                <Dropdown
                                    options={Status}
                                    value={status}
                                    optionLabel="name"
                                    onChange={onStatusChange}
                                    placeholder="Select a Status"
                                    className={classNames({ 'p-invalid': submitted && !status })}
                                    checkmark={true}
                                    highlightOnSelect={false}
                                    filter
                                />
                                {submitted && !lead.locationLatitude && <small className="p-invalid">Default currency is required.</small>}
                            </div>
                        </div>
                        <div className=" formgrid grid">
                            <div className="field col">
                                <label htmlFor="source">Lead Source</label>
                                <Dropdown
                                    options={Source}
                                    value={source}
                                    optionLabel="name"
                                    onChange={onSourceChange}
                                    placeholder="Select a Source"
                                    className={classNames({ 'p-invalid': submitted && !status })}
                                    checkmark={true}
                                    highlightOnSelect={false}
                                    filter
                                />
                                {submitted && !lead.locationLatitude && <small className="p-invalid">Default currency is required.</small>}
                            </div>
                        </div>
                        <div className=" formgrid grid">
                            <div className="field col">
                                <label htmlFor="remarks">Remarks</label>
                                <InputText
                                    id="locationLongitude"
                                    value={lead.remark || ''}
                                    onChange={(e) => onInputChange(e, 'remark')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !lead.locationLongitude
                                    })}
                                />
                                {submitted && !lead.remark && <small className="p-invalid">Remarks is required.</small>}
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteLeadDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteLeadDialogFooter} onHide={hideShopDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {lead && (
                                <span>
                                    Are you sure you want to delete <b>{lead.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default Leads;
