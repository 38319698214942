import * as routPaths from './NavigationRoutes';
import AttendantDashboard from '../pages/attendant/AttendantDashboard';
import StockLevel from '../pages/administrator/StockLevel';
import StockEntry from '../pages/administrator/StockEntry';
import HeldSalesDesk from '../pages/HeldSalesDesk.js';
import ShopSales from '../pages/administrator/ShopSales';
import AttendantSales from '../pages/attendant/AttendantSales.js';
import StorageParams from '../constants/StorageParams.js';

export class ShopAttendantNavigationConfig {
    navigationMenu = [
        {
            items: [
                {
                    label: 'Dashboard',
                    icon: 'bx bxs-dashboard',
                    to: routPaths.HOME_ROUTE_PATH
                }
            ]
        },
        {
            items: [
                {
                    label: 'Sales Desk',
                    icon: 'bx bx-receipt',
                    to: routPaths.SHOP_SALES_ROUTE_PATH
                }
            ]
        },
        // {
        //     items: [
        //         {
        //             label: 'Held Transactions',
        //             icon: 'bx bx-pause',
        //             to: routPaths.SHOP_HELD_SALES_ROUTE_PATH,
        //             badge: JSON.parse(localStorage.getItem(StorageParams.HELD_TRANSACTIONS))?.length
        //         }
        //     ]
        // },
        {
            items: [{ label: 'Shop Management ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'Shop Sales',
                    icon: 'bx bx-cart',
                    to: routPaths.REPORTS_ROUTE_PATH
                }
            ]
        },
        {
            items: [
                {
                    label: 'Stock level',
                    icon: 'bx bx-objects-vertical-bottom',
                    to: routPaths.STOCK_LEVELS_ROUTE_PATH
                }
            ]
        },
        {
            items: [
                {
                    label: 'Stock purchase',
                    icon: 'bx bx-layer-plus',
                    to: routPaths.STOCK_ENTRIES_ROUTE_PATH
                }
            ]
        },
        {
            items: [{ label: 'Settings ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'Logout',
                    icon: 'bx bx-log-out',
                    to: routPaths.LOGIN_ROUTE_PATH
                }
            ]
        }
    ];

    componentRouters = [
        {
            path: routPaths.DASHBOARD_ROUTE_PATH,
            label: 'Dashboard',
            component: <AttendantDashboard />,
            exact: true
        },
        {
            path: routPaths.SHOP_HELD_SALES_ROUTE_PATH,
            label: 'Held Sales',
            component: <HeldSalesDesk />,
            exact: true
        },
        {
            path: routPaths.HOME_ROUTE_PATH,
            label: 'Dashboard',
            component: <AttendantDashboard />,
            exact: true
        },
        {
            path: routPaths.STOCK_LEVELS_ROUTE_PATH,
            label: 'StockLevel',
            component: <StockLevel />,
            exact: true
        },
        {
            path: routPaths.STOCK_ENTRIES_ROUTE_PATH,
            label: 'StockEntry',
            component: <StockEntry />,
            exact: true
        },
        {
            path: routPaths.SHOP_SALES_ROUTE_PATH,
            label: 'Sales Desk',
            component: <ShopSales />,
            exact: true
        },
        {
            path: routPaths.REPORTS_ROUTE_PATH,
            label: 'Sales Desk',
            component: <AttendantSales />,
            exact: true
        }
    ];

    getNavigationMenu() {
        return this.navigationMenu;
    }

    getComponentRouters() {
        return this.componentRouters;
    }
}
