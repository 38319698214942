import React from 'react';
import { toReadableDateTime } from '../../utils/Utils';

function StockListingRow({ rowData }) {
    return (
        <div className="field ml-6 pl-4">
            <div className="grid col-12">
                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium">Manufacturer:</span>
                        <br /> {rowData.manufacturerName}
                    </label>
                </div>

                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium">Package Unit:</span>
                        <br /> <>{rowData.packageUnitName}</>
                    </label>
                </div>

                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium">Package quantity:</span>
                        <br /> <>{rowData.packageQuantity}</>
                    </label>
                </div>
            </div>
            <div className="grid col-12">
                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium"> Shop name: </span>
                        <br /> <>{rowData.shopName}</>
                    </label>
                </div>

                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium">Has MultipleSale Units:</span>
                        <br /> <>{rowData.hasMultipleSaleUnits ? 'True' : 'False'}</>
                    </label>
                </div>

                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium"> Action on:</span>
                        <br /> <>{toReadableDateTime(rowData.dateCreated)}</>
                    </label>
                </div>

                <div className="col-12 md:col-6 lg:col-3">
                    <label className="mt-3">
                        <span className="font-medium">Created by:</span>
                        <br /> {rowData.createdByFullName}
                    </label>
                </div>
            </div>

            <div className="grid col-12">
                {rowData.remarks && (
                    <div className="col-12">
                        <label className="mt-3">
                            <span className="font-medium "> Remarks:</span> <br /> <span className="wrap">{rowData?.remarks}</span>
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
}

export default StockListingRow;
