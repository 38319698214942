import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, hasNull } from '../../utils/Utils';
import StatusSummary from '../../components/StatusSummary';

const Currencies = () => {
    let initialCurrency = {
        id: 0,
        name: null,
        symbol: null,
        referenceCode: null
    };

    const [CurrencyValues, setCurrencyValues] = useState(null);
    const [countries, setCountries] = useState(null);
    const [CurrencyValueDialog, setCurrencyValueDialog] = useState(false);
    const [deleteCurrencyDialog, setDeleteCurrencyDialog] = useState(false);
    const [deleteCurrenciesDialog, setDeleteCurrenciesDialog] = useState(false);
    const [currency, setCurrency] = useState(initialCurrency);
    const [selectedCurrencyValues, setSelectedCurrencyValues] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [loading, setLoading] = useState(false);

    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchCurrencies = async () => {
        setLoading(true);

        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/currencies')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setCurrencyValues(response.records);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const fetchCountries = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/lookups/countries')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setCountries(response.records);
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        fetchCurrencies();
    }, [refresh]);

    const openNew = () => {
        setCurrency(initialCurrency);
        setSubmitted(false);
        setCurrencyValueDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCurrencyValueDialog(false);
    };

    const hideCurrencyValueDialog = () => {
        setDeleteCurrencyDialog(false);
    };

    const hideDeleteShopsDialog = () => {
        setDeleteCurrenciesDialog(false);
    };

    const saveCurrencyValue = () => {
        setSubmitted(true);
        let payload = {
            name: currency?.name,
            decimalPlaces: 2,
            symbol: currency?.symbol,
            referenceCode: currency?.referenceCode,
            countryId: selectedCountry?.id
        };
        setLoading(true);

        if (hasNull(payload) === false) {
            new BaseApiService('/currencies')
                .postRequestWithJsonResponse(payload)
                .then((response) => {
                    let _currencyValues = [...CurrencyValues];

                    let _currencyValue = { ...response };

                    _currencyValues.push(_currencyValue);

                    setCurrencyValues(_currencyValues);

                    setCurrencyValueDialog(false);
                    setCurrency(initialCurrency);
                    fetchCurrencies();
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Currency Details saved',
                        life: 3000
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error.message);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    const editLookUpValue = (lookUpValue) => {
        setEdit(true);

        setCurrency({ ...lookUpValue });
        setSelectedCountry(countries.find((obj) => obj.id === lookUpValue.typeId));
        setCurrencyValueDialog(true);
    };

    const confirmDeleteLookUpValue = (lookUpValue) => {
        setCurrency(lookUpValue);
        setDeleteCurrencyDialog(true);
    };

    const deleteCurrency = () => {
        new BaseApiService('/currencies/' + currency.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _lookUpValues = CurrencyValues.filter((val) => val.id !== currency.id);
                setCurrencyValues(_lookUpValues);
                setDeleteCurrencyDialog(false);
                setCurrency(initialCurrency);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Currency Deleted',
                    life: 3000
                });
            })
            .catch((error) => {});
    };

    const confirmDeleteSelected = () => {
        setDeleteCurrenciesDialog(true);
    };

    const deleteCurrencies = () => {
        let _currencies = CurrencyValues.filter((val) => !selectedCurrencyValues.includes(val));
        setCurrencyValues(_currencies);
        setDeleteCurrenciesDialog(false);
        setSelectedCurrencyValues(null);
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: 'Currencies Deleted',
            life: 3000
        });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _currency = { ...currency };
        _currency[`${name}`] = val;

        setCurrency(_currency);
    };

    const onCurrencyChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const valueBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Symbol</span>
                {rowData.symbol}
            </>
        );
    };

    const lookupTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reference Code</span>
                {rowData.referenceCode}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Country</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created By</span>
                {rowData.country.name}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editLookUpValue(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteLookUpValue(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Currencies</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} style={selectedCurrencyValues && selectedCurrencyValues.length > 1 ? { display: 'inline-flex' } : { display: 'none' }} />
            </div>
        </div>
    );

    const lookUpValueDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button loading={loading} label="Save" icon="pi pi-check" className="primary-btn" onClick={saveCurrencyValue} />
        </>
    );
    const deleteCurrencyDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button outline-btn" onClick={hideCurrencyValueDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button primary-btn" onClick={deleteCurrency} />
        </>
    );
    const deleteCurrenciesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button outline-btn" onClick={hideDeleteShopsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button primary-btn" onClick={deleteCurrencies} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    style={{
                        color: 'var(--text-color)',
                        userSelect: 'none',
                        width: 'auto',
                        textAlign: 'center'
                    }}
                >
                    Showing {options.first} to {options.last} of {options.totalRecords} currencies
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Total" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Categories" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Active" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        loading={loading}
                        ref={dt}
                        value={CurrencyValues}
                        selection={selectedCurrencyValues}
                        onSelectionChange={(e) => setSelectedCurrencyValues(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        globalFilter={globalFilter}
                        emptyMessage="No Currency found."
                        header={header}
                    >
                        <Column field="value" header="Name" body={valueBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column field="type" header="Symbol" body={lookupTypeBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }} />
                        <Column field="description" header="Reference Code" body={descriptionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column field="dateCreated" header="Country" body={dateCreatedBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} />
                        <Column body={actionBodyTemplate} header="Actions" />
                    </DataTable>

                    <Dialog visible={CurrencyValueDialog} style={{ width: '700px' }} header="New Currency" modal className="p-fluid" footer={lookUpValueDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        <div className="field" style={{ marginTop: '10px' }}>
                            <label htmlFor="name">Name</label>
                            <InputText
                                id="name"
                                value={currency.name || ''}
                                onChange={(e) => onInputChange(e, 'name')}
                                required
                                autoFocus
                                className={classNames({
                                    'p-invalid': submitted && !currency.name
                                })}
                            />
                            {submitted && !currency.name && <small className="p-error">Currency name is required.</small>}
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="symbol">Symbol</label>
                                <InputText
                                    id="symbol"
                                    value={currency.symbol || ''}
                                    onChange={(e) => onInputChange(e, 'symbol')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !currency.symbol
                                    })}
                                />
                                {submitted && !currency.symbol && <small className="p-error">Currency symbol is required.</small>}
                            </div>

                            <div className="field col">
                                <label htmlFor="refCode">Reference Code</label>
                                <InputText
                                    id="refCode"
                                    value={currency.referenceCode || ''}
                                    onChange={(e) => onInputChange(e, 'referenceCode')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !currency.referenceCode
                                    })}
                                />
                                {submitted && !currency.referenceCode && <small className="p-error">Currency ISO Code is required.</small>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="country">Country</label>
                            <Dropdown
                                id="country"
                                value={selectedCountry}
                                options={countries}
                                onChange={onCurrencyChange}
                                optionLabel={'name'}
                                filter
                                showClear
                                filterBy="name"
                                placeholder="Select Country"
                                className={classNames({
                                    'p-invalid': submitted && !selectedCountry
                                })}
                            />
                            {submitted && !selectedCountry && <small className="p-error">Country is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCurrencyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCurrencyDialogFooter} onHide={hideCurrencyValueDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {currency && (
                                <span>
                                    Are you sure you want to delete <b>{currency.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCurrenciesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCurrenciesDialogFooter} onHide={hideDeleteShopsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {currency && <span>Are you sure you want to delete the selected currencies?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default Currencies;
