import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { classNames } from 'primereact/utils';

export default function NamesInput({ onNext, onCancel, names }) {
    //names prop is to keep the field not empty
    const [lastname, setLastname] = useState(names?.lastname);
    const [firstname, setFirstname] = useState(names?.firstname);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleNameValidation = () => {
        setSubmitted(true);
        if (!firstname || !lastname) {
            setError('Fill in empty field');
        } else {
            // setInputNum(0);
            onNext(firstname, lastname);
        }
    };
    return (
        <>
            <div className="words-div">
                <h1>Create a Duqact Account</h1>
                <p>Enter your names for personalized identification.</p>
            </div>
            <div className="inputs-div">
                <div className="input-div">
                    <label>First name</label>
                    <input
                        htmlFor="firstname"
                        type="text"
                        onFocus={() => setSubmitted(false)}
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        placeholder="First name"
                        className={classNames({
                            'error-input': submitted === true && !firstname
                        })}
                    />
                    {submitted && !firstname && <label id="error">{error}</label>}
                </div>
                <div className="input-div">
                    <label>Last Name</label>
                    <input
                        htmlFor="lastname"
                        onFocus={() => setSubmitted(false)}
                        type="text"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        placeholder="Last name"
                        className={classNames({
                            'error-input': submitted === true && !lastname
                        })}
                    />
                    {submitted && !lastname && <label id="error">{error}</label>}
                </div>
                <div>
                    <Button onClick={onCancel} className="cancel" label=" Cancel" />

                    <Button onClick={handleNameValidation} className="send" label=" Next" />
                </div>
            </div>
        </>
    );
}
