import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BaseApiService } from '../../utils/BaseApiService';
import { useNavigate } from 'react-router';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import PhoneInput from 'react-phone-input-2';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';
import StatusSummary from '../../components/StatusSummary';
import EditAttendantRoles from '../../components/dialogs/EditAttendantRoles';
import FilterComponent from 'components/FilterComponent';
import { SHOPS_ENDPOINT } from 'utils/EndpointsUtils';

const ShopAttendant = () => {
    let emptyShopAttendant = {
        id: null,
        firstName: null,
        lastName: null,
        preferredUsername: null,
        otherName: null,
        passportPhoto: null,
        emailAddress: null,
        nationalID: null,
        nationalIDNumber: null,
        primaryPhoneNumber: null,
        alternativePhoneNumber: null,
        postalAddress: null,
        lc1Letter: null,
        dateOfBirth: null,
        genderId: 1,
        genderName: null,
        shopId: null,
        shopName: null
    };
    //Hooks for datatable filters
    const [shopFilter, setShopFilter] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [shopAttendants, setShopAttendants] = useState(null);
    const [shopAttendantDialog, setShopAttendantDialog] = useState(false);
    const [deleteShopAttendantDialog, setDeleteShopAttendantDialog] = useState(false);
    const [deleteShopAttendantsDialog, setDeleteShopAttendantsDialog] = useState(false);
    const [shopAttendant, setShopAttendant] = useState(emptyShopAttendant);
    const [selectedShopAttendants, setSelectedShopAttendants] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [genders, setGenders] = useState(null);
    const [countries, setCountries] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedShop, setSelectedShop] = useState(null);
    const [selectedShop2, setSelectedShop2] = useState(null);

    const [shops, setShops] = useState([]);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [maleAttendants, setMaleAtendants] = useState(null);
    const [femaleAttendants, setFemaleAttendants] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [altphoneNumber, setAltPhoneNumber] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [roleDialog, setRoleDialog] = useState(false);
    const [selectedShopAttendant, setSelectedShopAttendant] = useState(null);

    const dialogMessage = useRef();
    const navigate = useNavigate();
    const toast = useRef(null);
    const dt = useRef(null);

    const isShopOwner = UserSessionUtils.getShopOwner();
    const fetchShops = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };

        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        new BaseApiService(SHOPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response.records.length > 1 && isShopOwner) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }

                setShops(response.records);
            })
            .catch((error) => {});
    };

    const fetchShopAttendants = async () => {
        setIsLoading(true);

        let searchParameters = {
            offset: 0,
            limit: 0,
            ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
        };
        if (searchTerm != undefined && searchTerm != null) {
            searchParameters.searchTerm = searchTerm;
        }
        if (selectedShop2) {
            searchParameters.shopId = selectedShop2?.id;
        }

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        new BaseApiService('/shops/attendants')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShopAttendants(response.records);
                let count = 0;
                for (let item of response.records) {
                    if (item.genderName === 'Male') {
                        count += 1; // the males
                    }
                }
                setMaleAtendants(count);
                setFemaleAttendants(response.records.length - count);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const fetchGenders = async () => {
        new BaseApiService('/lookups/genders')
            .getRequestWithJsonResponse()
            .then(async (response) => {
                setGenders(response?.records);
            })
            .catch((error) => {});
    };

    const fetchCountries = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/lookups/countries')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setCountries(response?.records);
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchGenders();
        fetchCountries();
        fetchShops();
    }, []);

    useEffect(() => {
        fetchShopAttendants();
    }, [refresh, activeIndex]);

    const openNew = () => {
        setShopAttendant(emptyShopAttendant);
        setSubmitted(false);
        setShopAttendantDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setShopAttendantDialog(false);
    };

    const hideShopAttendantDialog = () => {
        setDeleteShopAttendantDialog(false);
    };

    const hideDeleteShopAttendantsDialog = () => {
        setDeleteShopAttendantsDialog(false);
    };

    const saveShopAttendant = () => {
        setSubmitted(true);
        const apiUrl = edit && edit === true ? '/shops/attendants/' + shopAttendant.id : '/shops/attendants';

        new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(
                {
                    firstName: shopAttendant.firstName,
                    lastName: shopAttendant.lastName,
                    preferredUsername: shopAttendant.preferredUsername,
                    otherName: shopAttendant.otherName,
                    emailAddress: shopAttendant.emailAddress,
                    nationalID: shopAttendant.nationalID,
                    nationalIDNumber: shopAttendant.nationalIDNumber,
                    primaryPhoneNumber: phoneNumber,
                    alternativePhoneNumber: altphoneNumber,
                    postalAddress: shopAttendant.postalAddress,
                    lc1Letter: shopAttendant.lc1Letter,
                    genderId: selectedGender.id,
                    shopId: selectedShop.id
                },
                edit
            )
            .then((response) => {
                if (edit && edit === true) {
                    setRefresh(true);
                } else {
                    let _shopAttendants = [...shopAttendants];

                    let _shopAttendant = { ...response };

                    _shopAttendants.push(_shopAttendant);

                    setShopAttendants(_shopAttendants);
                }
                setShopAttendantDialog(false);
                setShopAttendant(emptyShopAttendant);
                setSelectedCountry(null);
                setSelectedGender(null);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Shop Attendant Details saved',
                    life: 3000
                });
                fetchShopAttendants();
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error.message);
            });
    };

    const editShopAttendant = (shopAttendant) => {
        setEdit(true);

        setShopAttendant({ ...shopAttendant });
        setSelectedShop(shops.find((obj) => obj.id === shopAttendant.shopId));
        setSelectedGender(genders.find((obj) => obj.id === shopAttendant.genderId));
        setPhoneNumber(shopAttendant.primaryPhoneNumber);
        setAltPhoneNumber(shopAttendant.alternativePhoneNumber);
        setShopAttendantDialog(true);
    };

    const confirmDeleteShopAttendant = (shopAttendant) => {
        setShopAttendant(shopAttendant);
        setDeleteShopAttendantDialog(true);
    };

    const deleteShopAttendant = () => {
        new BaseApiService('/shops/attendants/' + shopAttendant.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _shopAttendants = shopAttendants.filter((val) => val.id !== shopAttendant.id);
                setShopAttendants(_shopAttendants);
                setDeleteShopAttendantDialog(false);
                setShopAttendant(emptyShopAttendant);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Shop attendant Deleted',
                    life: 3000
                });
            })
            .catch((error) => {});
    };

    const confirmDeleteSelected = () => {
        setDeleteShopAttendantsDialog(true);
    };

    const deleteSelectedShopAttendants = () => {
        let _shopAttendants = shopAttendants.filter((val) => !selectedShopAttendants.includes(val));
        setShopAttendants(_shopAttendants);
        setDeleteShopAttendantsDialog(false);
        setSelectedShopAttendants(null);
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: 'Shop attendants Deleted',
            life: 3000
        });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _shopOwner = { ...shopAttendant };
        _shopOwner[`${name}`] = val;

        setShopAttendant(_shopOwner);
    };

    const onGenderChange = (e) => {
        setSelectedGender(e.target.value);
    };

    const onCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const shopBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop name</span>
                {rowData.shopName}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Full name</span>
                {rowData.firstName + ' ' + rowData.lastName}
            </>
        );
    };

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Username</span>
                {rowData.preferredUsername}
            </>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone number</span>
                {rowData.primaryPhoneNumber}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success mr-2" onClick={() => editShopAttendant(rowData)} />
                <Button
                    icon="pi pi-shield"
                    className="p-button-rounded p-button-text p-button-success mr-2"
                    onClick={() => {
                        setSelectedShopAttendant(rowData);
                        setRoleDialog(true);
                    }}
                />

                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mr-2" onClick={() => confirmDeleteShopAttendant(rowData)} />
            </div>
        );
    };

    const onShopChange = (e) => {
        setSelectedShop(e.target.value);
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Shop attendants</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchShopAttendants} />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} style={selectedShopAttendants && selectedShopAttendants.length > 1 ? { display: 'inline-flex' } : { display: 'none' }} />
            </div>
        </div>
    );

    const shopAttendantDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="primary-btn" onClick={saveShopAttendant} />
        </>
    );
    const deleteShopAttendantDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopAttendantDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteShopAttendant} />
        </>
    );
    const deleteShopAttendantsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideDeleteShopAttendantsDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteSelectedShopAttendants} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    style={{
                        color: 'var(--text-color)',
                        userSelect: 'none',
                        width: 'auto',
                        textAlign: 'center'
                    }}
                >
                    Showing {options.first} to {options.last} of {options.totalRecords} shop attendants
                </span>
            );
        }
    };

    const dynamicTabs = () => {
        if (shops?.length > 1 && !UserSessionUtils.getSuperAdmin()) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="h-2rem">
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(shops.length > 1 ? shops.length - 1 : shops.length || 0)} />
                        <StatusSummary title="Male" value={formatNumberWithCommas(maleAttendants)} iconName="bx bx-receipt" />
                        <StatusSummary title="Female" value={formatNumberWithCommas(femaleAttendants)} iconName="pi pi-money-bill" />
                        <StatusSummary title="Total" value={formatNumberWithCommas(maleAttendants + femaleAttendants)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <EditAttendantRoles
                    selectedRecord={selectedShopAttendant}
                    roleDialog={roleDialog}
                    setRoleDialog={setRoleDialog}
                    onComplete={() => {
                        fetchShopAttendants();
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Shop Attendant Details saved',
                            life: 3000
                        });
                    }}
                />
                {UserSessionUtils.getSuperAdmin() && (
                    <FilterComponent onFilter={fetchShopAttendants} onClear={() => setSelectedShop2(null)}>
                        <div className="field col-4">
                            <label htmlFor="shop">Shop</label>
                            <Dropdown id="shop" showClear value={selectedShop2} options={shops} onChange={(e) => setSelectedShop2(e.target.value)} optionLabel={'name'} filter filterBy="name" placeholder="Select Shop" />
                        </div>
                    </FilterComponent>
                )}
                <div className="card">
                    <Toast ref={toast} />

                    {header}

                    <DataTable
                        ref={dt}
                        value={shopAttendants}
                        selection={selectedShopAttendants}
                        dataKey="id"
                        paginator
                        rows={10}
                        loading={isLoading}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        emptyMessage="No shop attendants found."
                        header={dynamicTabs}
                        size="small"
                    >
                        <Column field="fullName" header="Name" body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="username" header="Username" body={usernameBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} />
                        <Column field="phoneNumber" header="Phone" body={phoneNumberBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="shop" header="Shop" body={shopBodyTemplate} headerStyle={{ width: '24%', minWidth: '10rem' }} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }} />
                        <Column body={actionBodyTemplate} header="Actions" />
                    </DataTable>

                    <Dialog visible={shopAttendantDialog} style={{ width: '700px' }} header="Shop Attendant Details" modal className="p-fluid" footer={shopAttendantDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        <div className="field" style={{ marginTop: '10px' }}>
                            <label htmlFor="shop">Shop</label>
                            <Dropdown id="shop" value={selectedShop} options={shops} onChange={onShopChange} optionLabel={'name'} filter showClear filterBy="name" placeholder="Select a Shop" />
                            {submitted && !selectedShop && <small className="p-invalid">Shop is required.</small>}
                        </div>

                        <div className="formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="firstName">First Name</label>
                                <InputText
                                    id="firstName"
                                    value={shopAttendant.firstName || ''}
                                    onChange={(e) => onInputChange(e, 'firstName')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.firstName
                                    })}
                                />
                                {submitted && !shopAttendant.firstName && <small className="p-invalid">First name is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="lastName">Last Name</label>
                                <InputText
                                    id="lastName"
                                    value={shopAttendant.lastName || ''}
                                    onChange={(e) => onInputChange(e, 'lastName')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.lastName
                                    })}
                                />
                                {submitted && !shopAttendant.lastName && <small className="p-invalid">Last name is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="otherName">Other Name</label>
                                <InputText id="otherName" value={shopAttendant.otherName || ''} onChange={(e) => onInputChange(e, 'otherName')} />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-6 md:col-4">
                                <label htmlFor="preferredUsername">Username</label>
                                <InputText
                                    id="preferredUsername"
                                    value={shopAttendant.preferredUsername || ''}
                                    onChange={(e) => onInputChange(e, 'preferredUsername')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.preferredUsername
                                    })}
                                />
                                {submitted && !shopAttendant.preferredUsername && <small className="p-invalid">Username is required.</small>}
                            </div>
                            <div className="field col-6 md:col-4">
                                <label htmlFor="emailAddress">Email Address</label>
                                <InputText
                                    id="emailAddress"
                                    value={shopAttendant.emailAddress || ''}
                                    onChange={(e) => onInputChange(e, 'emailAddress')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.emailAddress
                                    })}
                                />
                                {submitted && !shopAttendant.emailAddress && <small className="p-invalid">Email address is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="gender">Gender</label>
                                <Dropdown id="gender" value={selectedGender} options={genders} required onChange={onGenderChange} optionLabel="name" placeholder="Select a Gender" />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-6 md:col-6">
                                <label htmlFor="primaryPhoneNumber">Phone number</label>
                                <PhoneInput
                                    id="primaryPhoneNumber"
                                    className={classNames({
                                        'country-code-reduced error-Input': submitted && !phoneNumber,
                                        'country-code-reduced': true
                                    })}
                                    value={phoneNumber || ''}
                                    onChange={(e) => setPhoneNumber(e)}
                                    country={'ug'}
                                    onlyCountries={['ug', 'ke']}
                                    inputProps={{
                                        required: true
                                    }}
                                />
                                {submitted && !phoneNumber && <small className="p-invalid">Phone number is required.</small>}
                            </div>
                            <div className="field col-6 md:col-6">
                                <label htmlFor="alternativePhoneNumber">Alternative number</label>
                                <PhoneInput
                                    id="altPhoneNumber"
                                    className={classNames({ 'country-code-reduced': true })}
                                    value={altphoneNumber || ''}
                                    onChange={(e) => setAltPhoneNumber(e)}
                                    country={'ug'}
                                    onlyCountries={['ug', 'ke']}
                                    inputProps={{
                                        required: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-6 md:col-6">
                                <label htmlFor="nationalIDNumber">National ID Number</label>
                                <InputText
                                    id="nationalIDNumber"
                                    value={shopAttendant.nationalIDNumber || ''}
                                    onChange={(e) => onInputChange(e, 'nationalIDNumber')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.nationalIDNumber
                                    })}
                                />
                                {submitted && !shopAttendant.nationalIDNumber && <small className="p-invalid">National ID Number is required.</small>}
                            </div>
                            <div className="field col-6 md:col-6">
                                <label htmlFor="lc1Letter">LC letter</label>
                                <InputText
                                    id="lc1Letter"
                                    value={shopAttendant.lc1Letter || ''}
                                    onChange={(e) => onInputChange(e, 'lc1Letter')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.lc1Letter
                                    })}
                                />
                                {submitted && !shopAttendant.lc1Letter && <small className="p-invalid">LC letter is required.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="postalAddress">Postal Address</label>
                                <InputText
                                    id="postalAddress"
                                    value={shopAttendant.postalAddress || ''}
                                    onChange={(e) => onInputChange(e, 'postalAddress')}
                                    required
                                    autoFocus
                                    className={classNames({
                                        'p-invalid': submitted && !shopAttendant.postalAddress
                                    })}
                                />
                                {submitted && !shopAttendant.postalAddress && <small className="p-invalid">Postal address is required.</small>}
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteShopAttendantDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteShopAttendantDialogFooter} onHide={hideShopAttendantDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {shopAttendant && (
                                <span>
                                    Are you sure you want to delete <b>{shopAttendant.firstName}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteShopAttendantsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteShopAttendantsDialogFooter} onHide={hideDeleteShopAttendantsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {shopAttendant && <span>Are you sure you want to delete the selected shopAttendants?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ShopAttendant;
