import React, { useEffect, useState } from 'react';
import { formatNumberWithCommas, toReadableDate, toReadableTime } from '../../utils/Utils';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserSessionUtils } from 'utils/UserSessionUtils';

function SaleSummaryDialog({ hideDialog, visible, selectedSale }) {
    const [itemCount, setItemCount] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);

    useEffect(() => {
        if (selectedSale !== null) {
            const { lineItems } = selectedSale;
            let items = lineItems.reduce((a, item) => a + item?.quantity, 0);
            let cartProfit = lineItems.reduce((a, i) => a + i.totalProfit, 0);

            setItemCount(items);
            setTotalProfit(Math.round(cartProfit));
        }
    }, [selectedSale]);

    const unitCostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Unit cost</span>
                {formatNumberWithCommas(rowData?.unitCost)}
            </>
        );
    };

    const totalCostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total cost</span>
                {formatNumberWithCommas(rowData?.totalCost)}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        const { saleUnitName, shopProductName } = rowData;
        let unitName = saleUnitName ? ' - ' + saleUnitName : '';
        return (
            <>
                <span className="p-column-title">Item</span>
                {shopProductName + unitName}
            </>
        );
    };

    return (
        <Dialog visible={visible} style={{ width: '700px' }} header="Sale Summary" modal className="p-fluid" onHide={hideDialog}>
            <div className="field">
                <div className="flex justify-content-between mt-2">
                    <span>
                        <span className="font-semibold">{selectedSale?.shopName}</span>
                        <br />
                        <span className="text-sm"> SN: {selectedSale?.serialNumber}</span>
                    </span>

                    <span className="text-sm font-normal text-right">
                        {toReadableDate(selectedSale?.dateCreated)} <br />
                        <span className="text-center"> {toReadableTime(selectedSale?.dateCreated)}</span>
                    </span>
                </div>
                <DataTable value={selectedSale?.lineItems} dataKey="id" className="mt-3" emptyMessage="No items for sale found." size="small" resizableColumns>
                    <Column field="shopProductName" header="Item" body={nameBodyTemplate} className="text-left" />
                    <Column field="quantity" header="Qty" className="text-center" />
                    <Column field="unitCost" body={unitCostBodyTemplate} header="Cost" className="text-right" />
                    <Column field="totalCost" body={totalCostBodyTemplate} header="Amount" className="text-right" />
                </DataTable>

                <div className="px-2">
                    <div className="flex justify-content-between mt-2">
                        <span className="font-semibold">Total</span>
                        <span className="text-sm font-normal">
                            {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.totalCost)}</span>
                        </span>
                    </div>
                    <div className="flex justify-content-between mt-4">
                        <span className="font-semibold">Recieved</span>
                        <span className="text-sm font-normal">
                            {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.amountPaid)}</span>
                        </span>
                    </div>
                    <div className="flex justify-content-between mt-2">
                        <span className="font-semibold">
                            Purchased
                            <span className="text-lg font-normal">
                                {' '}
                                {itemCount} {itemCount > 1 ? 'items' : 'item'}
                            </span>
                        </span>
                        <span className="text-sm font-normal">
                            <span className="text-sm font-normal">
                                {selectedSale?.currency}
                                <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.totalCost)}</span>
                            </span>
                        </span>
                    </div>
                    <div className="flex justify-content-between mt-2">
                        <span className="font-semibold">Balance</span>
                        <span className="text-sm font-normal">
                            {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.balanceGivenOut)}</span>
                        </span>
                    </div>
                    {!UserSessionUtils.getShopAttendant() && (
                        <div className="flex justify-content-between mt-2">
                            <span className="font-semibold">Income</span>
                            <span className="text-sm font-normal">
                                {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(totalProfit)}</span>
                            </span>
                        </div>
                    )}

                    {selectedSale?.clientName && (
                        <div className="flex justify-content-between mt-2">
                            <span className="font-semibold">Client name</span>
                            <span className=" font-normal">{selectedSale?.clientName}</span>
                        </div>
                    )}

                    {selectedSale?.clientPhoneNumber && (
                        <div className="flex justify-content-between mt-2">
                            <span className="font-semibold">Client number</span>
                            <span className="font-normal">{selectedSale?.clientPhoneNumber}</span>
                        </div>
                    )}

                    <div className="flex justify-content-between mt-4 -mb-3">
                        <span className="font-medium text-sm">
                            Served by: <span className=" font-normal">{selectedSale?.createdByFullName}</span>
                        </span>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default SaleSummaryDialog;
