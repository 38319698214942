import React from 'react';
import { formatNumberWithCommas } from '../utils/Utils';

function RenderCurrency({ value, currencySymbol = '' }) {
    const className = currencySymbol?.length > 2 ? 'text-sm font-normal' : '';
    return (
        <>
            <span className={className}>{currencySymbol} </span>
            {formatNumberWithCommas(value)}
        </>
    );
}
export default RenderCurrency;
