import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { formatNumberWithCommas } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import StatusSummary from '../../components/StatusSummary';
import { SHOP_REPORTS_ROUTE_PATH, SHOP_STOCK_LEVELS_ROUTE_PATH } from '../../utils/NavigationRoutes';
import { generatePath, useNavigate } from 'react-router-dom';

const AdminStockLevel = () => {
    const [shopProducts, setShopProducts] = useState([]);
    const [limit, setLimit] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();

    let offset = 0;
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchShopProducts = async () => {
        setIsLoading(true);
        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            searchTerm: searchTerm
        };
        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }

        new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShopProducts(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchShopProducts();
    }, [searchTerm]);

    const handleSelection = (rowData) => {
        const { name } = rowData.value;
        let formartedName = String(name).split(' ').join('_');
        navigate(generatePath(SHOP_STOCK_LEVELS_ROUTE_PATH, { shopName: formartedName }), {
            state: {
                ...rowData.value
            }
        });
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchShopProducts();
    };

    const shopOwnerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Owner</span>
                {rowData.name}
            </>
        );
    };

    const packedQtyBodyTemplate = (rowData) => {
        return (
            <>
                {' '}
                <span className="p-column-title">Packed</span>
                {formatNumberWithCommas(rowData?.performanceSummary?.packedQuantity)}
            </>
        );
    };

    const unPackedQtyBodyTemplate = (rowData) => {
        return (
            <>
                {' '}
                <span className="p-column-title">Unpacked</span>
                {formatNumberWithCommas(rowData?.performanceSummary?.unpackedQuantity)}
            </>
        );
    };

    const qtySoldBodyTemplate = (rowData) => {
        return <>{formatNumberWithCommas(rowData?.performanceSummary?.totalQuantitySold)}</>;
    };

    const remainingStockBodyTemplate = (rowData) => {
        let result = rowData?.performanceSummary?.totalQuantityStocked - rowData?.performanceSummary?.totalQuantitySold;
        if (result === undefined || isNaN(result) || result < 1) {
            result = 0;
        }
        return <>{formatNumberWithCommas(result)}</>;
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Stock levels</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchShopProducts} />
                </div>
            </span>
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    style={{
                        color: 'var(--text-color)',
                        userSelect: 'none',
                        width: 'auto',
                        textAlign: 'center'
                    }}
                >
                    Showing {options.first} to {options.last} of {options.totalRecords} stock levels
                </span>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center align-items-center">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-text p-button-success" />
                <Button icon="pi pi-print" className="p-button-rounded p-button-text p-button-info" />
            </div>
        );
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="grid">
                    <StatusSummary title="Products" value={formatNumberWithCommas(totalRecords)} />
                    <StatusSummary title="Stockout" value="184" iconBg="bg-orange-100" iconName="pi-map-marker" iconColor=" text-orange-500" />
                    <StatusSummary title="Capital" value="170000" iconName="pi-inbox" iconColor="text-cyan-500" />
                    <StatusSummary title="Capital" value="170000" iconName="pi-inbox" iconColor="text-cyan-500" />
                </div>

                <div className="py-2 mt-2">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter stock">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Price range</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount range</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Batch no</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Purchase date</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Purchase by</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Expiry date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Category</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    label="Clear"
                                    icon="pi pi-times"
                                    className="p-button-text "
                                    style={{
                                        color: 'black',
                                        border: '1px solid black',
                                        margin: '5px',
                                        width: 'fit-content'
                                    }}
                                />
                                <Button
                                    label="Filter"
                                    icon="pi pi-check"
                                    className="p-button-text"
                                    style={{
                                        color: '#FFEB3B',
                                        border: '1px solid black',
                                        backgroundColor: 'black',
                                        margin: '5px',
                                        width: 'fit-content'
                                    }}
                                />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={shopProducts}
                        dataKey="id"
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate={template2}
                        emptyMessage="No stock levels found."
                        header={header}
                        selectionMode="single"
                        size="small"
                        resizableColumns
                        onSelectionChange={handleSelection}
                    >
                        <Column field="name" header="Shop" body={shopOwnerBodyTemplate} className="text-left" />

                        <Column header="In stock" body={remainingStockBodyTemplate} className="text-center" />

                        <Column
                            field="packedQty"
                            body={packedQtyBodyTemplate}
                            header="Least stocked"
                            className="text-center"
                            headerStyle={{
                                width: '15%',
                                minWidth: '10rem',
                                textAlign: 'left',
                                fontWeight: '500'
                            }}
                        />
                        <Column
                            field="unpackedQty"
                            body={unPackedQtyBodyTemplate}
                            header="Stocked out"
                            className="text-center"
                            headerStyle={{
                                width: '15%',
                                minWidth: '10rem',
                                textAlign: 'left',
                                fontWeight: '500'
                            }}
                        />

                        <Column
                            field="soldItems"
                            header="Sold "
                            body={qtySoldBodyTemplate}
                            className="text-center"
                            headerStyle={{
                                width: '10%',
                                minWidth: '10rem',
                                textAlign: 'left',
                                fontWeight: '500'
                            }}
                        />

                        {/* <Column field="finishedOn" header="Stockout on" body={finishedOnBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'left',  fontWeight: '500' }}/> */}
                        <Column body={actionBodyTemplate} header="Actions" className="text-center" />
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />
                </div>
            </div>
        </div>
    );
};
export default AdminStockLevel;
