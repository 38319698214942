import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { Password } from 'primereact/password';
import { validatePassword } from '../../utils/Utils';
import { classNames } from 'primereact/utils';
import { ReactComponent as Openeye } from '../../assets/icons/eye-alt-svgrepo-com.svg';
import { ReactComponent as Closedeye } from '../../assets/icons/eye-slash-alt-svgrepo-com.svg';

export default function PasswordInput({ onBackPress, onSubmit, loading }) {
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [hidden, setHidden] = useState(true);

    const handleIconClick = () => {
        setHidden(!hidden);
    };

    const ValidateForm = () => {
        setSubmitted(true);
        let truePassword = validatePassword(newpassword).length === 0 && newpassword === confirmpassword; //to check if no error messages are returned

        if (truePassword) {
            onSubmit({ newpassword });
        }
    };
    return (
        <>
            <div className="words-div">
                <h1>Password Security</h1>
                <p>Enter a strong password you can remember.</p>
            </div>
            <div className="inputs-div">
                <div className="input-div">
                    <label>Password</label>
                    {!hidden && <Openeye onClick={handleIconClick} className="eyes" />}
                    {hidden && <Closedeye onClick={handleIconClick} className="eyes" />}
                    <input
                        value={newpassword}
                        onFocus={() => setSubmitted(false)}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type={hidden ? 'password' : 'text'}
                        placeholder="New Password"
                        //className={isInputValid1 ? '' : 'error-input'}
                        className={classNames({
                            'error-input': validatePassword(newpassword)[0] > 0
                        })}
                    />
                    {newpassword && <label id="error">{validatePassword(newpassword)[0]}</label>}
                </div>
                <div className="input-div">
                    <label>Confirm Password</label>
                    <input
                        value={confirmpassword}
                        onFocus={() => setSubmitted(false)}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={hidden ? 'password' : 'text'}
                        placeholder="Confirm Password"
                        //className={isInputValid2 ? '' : 'error-input'}
                        className={classNames({
                            'error-input': newpassword && confirmpassword !== newpassword
                        })}
                    />

                    {newpassword && confirmpassword !== newpassword && <label id="error">Passwords do not match</label>}
                </div>
                <div>
                    <Button onClick={onBackPress} className="cancel">
                        Back
                    </Button>
                    <Button loading={loading} onClick={ValidateForm} label="Submit" />
                </div>
            </div>
        </>
    );
}
