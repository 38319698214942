import Dashboard from '../pages/Dashboard';
import HeldSalesDesk from '../pages/HeldSalesDesk.js';
import Distributor from '../pages/administrator/Distributor';
import LookUpValue from '../pages/administrator/LookUpValue';
import Currency from '../pages/administrator/Currencies';
import Manufacturer from '../pages/administrator/Manufacturer';
import ProductsView from '../pages/administrator/ProductsView';
import Shop from '../pages/administrator/ShopsList';
import Leads from '../pages/administrator/Leads.js';
import ShopAttendant from '../pages/administrator/ShopAttendant';
import ShopCapital from '../pages/administrator/ShopCapital';
import ShopOwner from '../pages/administrator/ShopOwner';
import StockEntry from '../pages/administrator/StockEntry';
import Supplier from '../pages/administrator/Supplier';
import User from '../pages/administrator/User';
import * as routPaths from './NavigationRoutes';
import ShopSales from '../pages/administrator/ShopSales';
import StockListing from '../pages/administrator/StockListing';
import Expenses from '../pages/administrator/Expenses';
import FinancialSummary from '../pages/administrator/FinancialSummary.js';
import AdminReports from '../pages/administrator/AdminReports';
import AdminStockLevel from '../pages/administrator/AdminStockLevel.js';
import Permissions from '../pages/administrator/Permissions';
import Role from '../pages/administrator/Role';
import CreditSales from '../pages/shopowner/CreditSales';
import CreditPayments from '../pages/shopowner/CreditPayments';
import ShopClients from '../pages/shopowner/ShopClients';
import ShopOwnerReports from '../pages/shopowner/ShopOwnerReports';
import ShopProduct from '../pages/administrator/StockLevel';
import ProductReports from '../pages/administrator/ProductReports.js';
import SubscriptionPlans from '../pages/administrator/SubscriptionPlans';
import Subscriptions from '../pages/administrator/Subscriptions';
import StorageParams from '../constants/StorageParams.js';
import SubscriptionPayments from 'pages/administrator/SubscriptionPayments';
import Damages from 'pages/administrator/Damages';
import Profile from 'pages/Profile';

export class AdminNavigationConfig {
    /**
     *
     * @param userDetails
     */
    constructor() {}

    navigationMenu = [
        {
            items: [{ label: 'Dashboard', icon: 'bx bxs-dashboard', to: '/' }]
        },
        {
            items: [
                {
                    label: 'Sales Desk',
                    icon: 'bx bx-receipt',
                    to: routPaths.SHOP_SALES_ROUTE_PATH
                }
            ]
        },
        // {
        //     items: [
        //         {
        //             label: 'Held Transactions',
        //             icon: 'bx bx-pause',
        //             to: routPaths.SHOP_HELD_SALES_ROUTE_PATH,
        //             badge: JSON.parse(localStorage.getItem(StorageParams.HELD_TRANSACTIONS))?.length > 0 ? JSON.parse(localStorage.getItem(StorageParams.HELD_TRANSACTIONS))?.length : ''
        //         }
        //     ]
        // },

        {
            items: [{ label: 'Shop Management ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'Reports',
                    icon: 'bx bxs-report',
                    to: 'u',
                    items: [
                        {
                            label: 'Sales',
                            icon: 'bx bx-cart',
                            to: routPaths.REPORTS_ROUTE_PATH
                        },
                        {
                            label: 'Sales by products',
                            icon: 'bx bx-layer-plus',
                            to: routPaths.PRODUCT_REPORTS_ROUTE_PATH
                        },
                        {
                            label: 'Stock level',
                            icon: 'bx bx-objects-vertical-bottom',
                            to: routPaths.STOCK_LEVELS_ROUTE_PATH
                        },
                        {
                            label: 'Financial summary',
                            icon: 'bx bx-line-chart',
                            to: routPaths.FINANCIAL_SUMMARY_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: ' Debt mgt',
                    icon: 'bx bxs-book-bookmark',
                    to: 'u',
                    items: [
                        {
                            label: 'Debtors',
                            icon: 'bx bx-user',
                            to: routPaths.SHOP_CLIENTS
                        },
                        {
                            label: 'Debt sales',
                            icon: 'bx bxs-bookmark-alt-minus',
                            to: routPaths.CREDIT_SALES_ROUTE_PATH
                        },
                        {
                            label: 'Payments',
                            icon: 'bx bx-money',
                            to: routPaths.CREDIT_PAYMENTS_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Manage stock',
                    icon: 'bx bx-layer',
                    to: 'u',
                    items: [
                        {
                            label: 'Stock purchase',
                            icon: 'bx bx-layer-plus',
                            to: routPaths.STOCK_ENTRIES_ROUTE_PATH
                        },
                        {
                            label: 'Stock listing',
                            icon: 'bx bx-list-plus',
                            to: routPaths.STOCK_LISTING_ROUTE_PATH
                        },
                        {
                            label: 'Damages',
                            icon: 'bx bx-list-plus',
                            to: routPaths.DAMAGES_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Shop mgt',
                    icon: 'bx bxs-store',
                    to: 'u',
                    items: [
                        {
                            label: 'Owners',
                            icon: 'bx bx-user',
                            to: routPaths.SHOP_OWNERS_ROUTE_PATH
                        },
                        {
                            label: 'Shops',
                            icon: 'bx bx-store',
                            to: routPaths.SHOPS_ROUTE_PATH
                        },
                        {
                            label: 'Shop users',
                            icon: 'bx bxs-user-account',
                            to: routPaths.SHOP_ATTENDANTS_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Finances',
                    icon: 'bx bx-briefcase-alt-2',
                    to: 'u',
                    items: [
                        {
                            label: 'Capital',
                            icon: 'bx bx-money',
                            to: routPaths.SHOP_CAPITAL_ROUTE_PATH
                        },
                        {
                            label: 'Expenses',
                            icon: 'bx bx-wallet',
                            to: routPaths.EXPENSES_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Leads',
                    icon: 'bx bxs-group',
                    to: routPaths.LEADS_ROUTE_PATH
                }
            ]
        },

        {
            items: [{ label: 'Manage Supply ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'Product chain',
                    icon: 'bx bx-git-branch',
                    to: 'u',
                    items: [
                        {
                            label: 'Manufacturers',
                            icon: 'bx bxs-factory',
                            to: routPaths.MANUFACTURERS_ROUTE_PATH
                        },
                        {
                            label: 'Distributors',
                            icon: 'bx bx-buildings',
                            to: routPaths.DISTRIBUTORS_ROUTE_PATH
                        },
                        {
                            label: 'Suppliers',
                            icon: 'bx bxs-truck',
                            to: routPaths.SUPPLIERS_ROUTE_PATH
                        },
                        {
                            label: 'Products',
                            icon: 'bx bx-purchase-tag-alt',
                            to: routPaths.PRODUCTS_ROUTE_PATH
                        }
                    ]
                }
            ]
        },
        {
            items: [{ label: 'Settings ', disabled: true }]
        },
        {
            items: [
                {
                    label: 'User mgt',
                    icon: ' bx bxs-user-badge',
                    to: 'u',
                    items: [
                        {
                            label: 'Users',
                            icon: 'bx bx-user',
                            to: routPaths.USERS_ROUTE_PATH
                        },
                        {
                            label: 'Roles',
                            icon: 'bx bxs-user-detail',
                            to: routPaths.ROLES_ROUTE_PATH
                        },
                        {
                            label: 'Permissions',
                            icon: 'bx bxs-user-check',
                            to: routPaths.PERMISSIONS
                        }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Subscription mgt',
                    icon: ' bx bx-book-open',
                    to: 'u',

                    items: [
                        {
                            label: 'Subscription Plans',
                            icon: 'bx bx-book-open',
                            to: routPaths.SUBSCRIPTION_PLANS_ROUTE_PATH
                        },
                        {
                            label: 'Subscriptions',
                            icon: 'bx bx-customize',
                            to: routPaths.SUBSCRIPTIONS_ROUTE_PATH
                        },
                        {
                            label: 'Subscription Payments',
                            icon: 'bx bx-credit-card',
                            to: routPaths.SUBSCRIPTION_PAYMENTS_ROUTE_PATH
                        }
                    ]
                }
            ]
        },

        {
            items: [
                {
                    label: 'Lookup values',
                    icon: 'bx bx-file-find',
                    to: routPaths.LOOKUPS_ROUTE_PATH
                }
            ]
        },
        {
            items: [
                {
                    label: 'Currencies',
                    icon: 'bx bx-money',
                    to: routPaths.CURRENCIES_ROUTE_PATH
                }
            ]
        },
        {
            items: [
                {
                    label: 'Logout',
                    icon: 'bx bx-log-out',
                    to: routPaths.LOGIN_ROUTE_PATH
                }
            ]
        }
    ];
    componentRouters = [
        {
            path: routPaths.DASHBOARD_ROUTE_PATH,
            label: 'Dashboard',
            component: <Dashboard />,
            exact: true
        },
        {
            path: routPaths.DISTRIBUTORS_ROUTE_PATH,
            label: 'Distributor',
            component: <Distributor />,
            exact: true
        },
        {
            path: routPaths.HOME_ROUTE_PATH,
            label: 'Dashboard',
            component: <Dashboard />,
            exact: true
        },
        {
            path: routPaths.LOOKUPS_ROUTE_PATH,
            label: 'LookUpValue',
            component: <LookUpValue />,
            exact: true
        },
        {
            path: routPaths.CURRENCIES_ROUTE_PATH,
            label: 'Currency',
            component: <Currency />,
            exact: true
        },
        {
            path: routPaths.MANUFACTURERS_ROUTE_PATH,
            label: 'Manufacturer',
            component: <Manufacturer />,
            exact: true
        },
        {
            path: routPaths.PRODUCTS_ROUTE_PATH,
            label: 'Product',
            component: <ProductsView />,
            exact: true
        },
        {
            path: routPaths.SHOPS_ROUTE_PATH,
            label: 'Shop',
            component: <Shop />,
            exact: true
        },
        {
            path: routPaths.SHOP_ATTENDANTS_ROUTE_PATH,
            label: 'ShopAttendant',
            component: <ShopAttendant />,
            exact: true
        },
        {
            path: routPaths.SHOP_CAPITAL_ROUTE_PATH,
            label: 'ShopCapital',
            component: <ShopCapital />,
            exact: true
        },
        {
            path: routPaths.SHOP_OWNERS_ROUTE_PATH,
            label: 'ShopOwner',
            component: <ShopOwner />,
            exact: true
        },
        {
            path: routPaths.STOCK_ENTRIES_ROUTE_PATH,
            label: 'StockEntry',
            component: <StockEntry />,
            exact: true
        },
        {
            path: routPaths.STOCK_LEVELS_ROUTE_PATH,
            label: 'Stock Level',
            component: <AdminStockLevel />,
            exact: true
        },
        {
            path: routPaths.SUPPLIERS_ROUTE_PATH,
            label: 'Supplier',
            component: <Supplier />,
            exact: true
        },
        {
            path: routPaths.SHOP_SALES_ROUTE_PATH,
            label: 'Supplier',
            component: <ShopSales />,
            exact: true
        },
        {
            path: routPaths.SHOP_HELD_SALES_ROUTE_PATH,
            label: 'Supplier',
            component: <HeldSalesDesk />,
            exact: true
        },
        {
            path: routPaths.STOCK_LISTING_ROUTE_PATH,
            label: 'Stock listing',
            component: <StockListing />,
            exact: true
        },
        {
            path: routPaths.USERS_ROUTE_PATH,
            label: 'User',
            component: <User />,
            exact: true
        },
        {
            path: routPaths.EXPENSES_ROUTE_PATH,
            label: 'Expenses',
            component: <Expenses />,
            exact: true
        },
        {
            path: routPaths.REPORTS_ROUTE_PATH,
            label: 'Reports',
            component: <ShopOwnerReports />,
            exact: true
        },
        {
            path: routPaths.FINANCIAL_SUMMARY_PATH,
            label: 'Financial Summary',
            component: <FinancialSummary />,
            exact: true
        },
        {
            path: routPaths.PERMISSIONS,
            label: 'Permissions',
            component: <Permissions />,
            exact: true
        },
        {
            path: routPaths.ROLES_ROUTE_PATH,
            label: 'Roles',
            component: <Role />,
            exact: true
        },
        {
            path: routPaths.CREDIT_SALES_ROUTE_PATH,
            label: 'Credit sales',
            component: <CreditSales />,
            exact: true
        },
        {
            path: routPaths.CREDIT_PAYMENTS_ROUTE_PATH,
            label: 'Credit sales',
            component: <CreditPayments />,
            exact: true
        },
        {
            path: routPaths.SHOP_CLIENTS,
            label: 'Clients',
            component: <ShopClients />,
            exact: true
        },
        {
            path: routPaths.PRODUCT_REPORTS_ROUTE_PATH,
            label: 'Product Reports',
            component: <ProductReports />,
            exact: true
        },
        {
            path: routPaths.SHOP_STOCK_LEVELS_ROUTE_PATH,
            label: 'Reports',
            component: <ShopProduct />,
            exact: true
        },
        {
            path: routPaths.LEADS_ROUTE_PATH,
            label: 'Leads',
            component: <Leads />,
            exact: true
        },
        {
            path: routPaths.SUBSCRIPTION_PLANS_ROUTE_PATH,
            label: 'Subscription Plans',
            component: <SubscriptionPlans />,
            exact: true
        },
        {
            path: routPaths.SUBSCRIPTIONS_ROUTE_PATH,
            label: 'Subscriptions',
            component: <Subscriptions />,
            exact: true
        },
        {
            path: routPaths.SUBSCRIPTION_PAYMENTS_ROUTE_PATH,
            label: 'Subscription Payments',
            component: <SubscriptionPayments />,
            exact: true
        },
        {
            path: routPaths.DAMAGES_ROUTE_PATH,
            label: 'Damages',
            component: <Damages />,
            exact: true
        },
        {
            path: routPaths.SHOP_OWNER_PROFILE_ROUTE_PATH,
            label: 'Shop Owner Profile',
            component: <Profile />,
            exact: true
        }
    ];

    getNavigationMenu() {
        return this.navigationMenu;
    }

    getComponentRouters() {
        return this.componentRouters;
    }
}
