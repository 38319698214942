import { formatNumberWithCommas, toReadableDate, toReadableTime } from '../utils/Utils';

export const populateTemplate = (data) => {
    const {
        shopName,
        serialNumber,
        shopPhoneNumber,

        //
        shopAddress,
        createdByFullName,
        totalCost,
        dateCreated,
        balanceGivenOut,
        currency,
        debtBalance,
        clientPhoneNumber,
        clientName,
        lineItems
    } = data;
    const template = `<!DOCTYPE html>
  <html lang="en">
    <head>
      <style>
        body {
          margin: 0;
          padding: 0;
          font-family: "PT Sans", sans-serif;
        }
  
        html,
        body {
          height: auto;
        }
  
        @page {
          size: 2.8in 11in;
          margin-top: 0cm;
          margin-left: 0cm;
          margin-right: 0cm;
        }
  
        table {
          width: 100%;
        }
  
        tr {
          width: 100%;
        }
  
        h1 {
          text-align: center;
          vertical-align: middle;
        }
  
        h3 {
          text-align: center;
          vertical-align: middle;
          margin-bottom: 0;
        }
  
        #logo {
          width: 60%;
          text-align: center;
          -webkit-align-content: center;
          align-content: center;
          padding: 5px;
          margin: 2px;
          display: block;
          margin: 0 auto;
        }
  
        header {
          width: 100%;
          text-align: center;
          -webkit-align-content: center;
          align-content: center;
          vertical-align: middle;
        }
  
        .headings {
          text-align: center;
          vertical-align: middle;
        }
  
        .items thead {
          text-align: center;
        }
  
        .center-align {
          text-align: center;
        }
  
        .bill-details td {
          font-size: 12px;
        }
  
        .receipt {
          font-size: medium;
        }
  
        .items .heading {
          font-size: 12.5px;
          text-transform: uppercase;
          border-top: 1px solid black;
          margin-bottom: 4px;
          border-bottom: 1px solid black;
          vertical-align: middle;
        }
  
        .items thead tr th:first-child,
        .items tbody tr td:first-child {
          width: 47%;
          min-width: 47%;
          max-width: 47%;
          word-break: break-all;
          text-align: left;
        }
  
        .items td {
          font-size: 12px;
          text-align: right;
          vertical-align: bottom;
        }
  
        .price::before {
          font-family: Arial;
          text-align: right;
        }
  
        .sum-up {
          text-align: right !important;
        }
  
        .total {
          font-size: 13px;
          border-top: 1px dashed black !important;
          border-bottom: 1px dashed black !important;
        }
  
        .total.text,
        .total.price {
          text-align: right;
        }
  
        .total.price::before {
        }
  
        .line {
          border-top: 1px solid black !important;
        }
  
        .heading.rate {
          width: 20%;
        }
  
        .heading.amount {
          width: 25%;
        }
  
        .heading.qty {
          width: 5%;
        }
  
        p {
          padding: 1px;
          margin: 0;
        }
  
        section,
        footer {
          font-size: 12px;
        }
      </style>
    </head>
  
    <body class="print">
      <h3>${shopName}</h3>
      <p class="headings">Location: ${shopAddress}</p>
      <p class="headings">Contact : ${shopPhoneNumber}</p>
  
      <table class="bill-details">
        <tbody>
          <tr>
            <td>Date : <span>${toReadableDate(dateCreated)}</span></td>
            <td>Time : <span>${toReadableTime(dateCreated)}</span></td>
          </tr>
        </tbody>
      </table>
  
      <table class="items">
        <thead>
          <tr>
            <th class="heading name">Item</th>
            <th class="heading qty">Qty</th>
            <th class="heading rate">Rate</th>
            <th class="heading amount">Amount</th>
          </tr>
        </thead>
  
        <tbody>
         ${lineItems?.map((item) => {
             return `<tr>
               <td>${item.shopProductName}</td>
               <td>${item.quantity}</td>
               <td class="price">
                  ${formatNumberWithCommas(item.unitCost, currency)}
               </td>
               <td class="price">
               ${formatNumberWithCommas(item.totalCost, currency)}
               </td>
             </tr>`;
         })}
  
          <tr>
            <td colspan="3" class="sum-up line">Subtotal</td>
            <td class="line price"> ${formatNumberWithCommas(totalCost, currency)}</td>
          </tr>
          <tr>
            <td colspan="3" class="sum-up">VAT</td>
            <td class="price">${currency} 0.00</td>
          </tr>
  
          <tr>
            <th colspan="3" class="total text">Total</th>
            <th class="total price">${formatNumberWithCommas(totalCost, currency)}</th>
          </tr>
        </tbody>
      </table>
      <section>
        <p>Txn type : <span>${balanceGivenOut < 0 ? 'DEBT' : 'CASH'}</span></p>
        <p>Served by : <span>${createdByFullName}</span></p>
  
        ${clientName ? `<p>Client name : <span>${clientName}</span></p>` : ''}
        ${clientPhoneNumber ? `<p>Client number : <span>${clientPhoneNumber}</span></p>` : ''}
        ${debtBalance > 0 ? `<p>OutStanding balance : <span>${formatNumberWithCommas(debtBalance, currency)}</span></p>` : ''}

  
  
        <p>Reciept SN : ${serialNumber}</p>
        <p></p>
        <p style="text-align: center">Thank you for your purchase!</p>
      </section>
      <footer style="text-align: center">
        <p>Powered by Satesoft</p>
        <p>www.duqact.com</p>
      </footer>
    </body>
  </html>
  `;

    return template;
};
