import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { convertToServerDate, formatNumberWithCommas } from '../utils/Utils';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { BaseApiService } from '../utils/BaseApiService';
import { UserSessionUtils } from '../utils/UserSessionUtils';
import { Messages } from 'primereact/messages';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import PhoneInput from 'react-phone-input-2';
import StorageParams from '../constants/StorageParams';
import { showErrorMessage } from '../constants/ErrorMessages';

function HeldModeForm({ showHeldDialog, hideHeldDialog, cart, selectedShop, toast, refreshTable, selectedCurrency = '', heldTrans }) {
    const [isLoading, setLoading] = useState(false);
    const [clientName, setClientName] = useState('');
    const [clientNumber, setClientNumber] = useState(null);
    const dialogMessage = useRef();

    const holdTrans = async () => {
        if (clientName !== null) {
            heldTrans?.push({
                id: heldTrans?.length + 1,
                clientName: clientName,
                clientNumber: clientNumber,
                received: cart.receivedAmount,
                sold: cart.totalAmount,
                currency: cart.currency,
                shopId: selectedShop?.id,
                shopOwnerId: selectedShop?.ownerId,
                createdBy: UserSessionUtils.getUserDetails().username,
                cart: [...cart.cartItems]
            });
            localStorage.setItem(StorageParams.HELD_TRANSACTIONS, JSON.stringify(heldTrans));
            refreshTable();
            hideDialog();
        } else {
            showErrorMessage(dialogMessage, 'Enter the clients credentials!');
        }
    };

    const unitCostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Unit cost</span>
                {formatNumberWithCommas(rowData?.unitCost)}
            </>
        );
    };

    const totalCostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total cost</span>
                {formatNumberWithCommas(rowData?.cost)}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Item</span>
                {rowData?.productName}
            </>
        );
    };

    const hideDialog = () => {
        hideHeldDialog();
        setLoading(false);
        setClientName('');
        setClientNumber(null);
    };

    const productDialogFooter = (
        <>
            <Button loading={isLoading} label="Cancel" icon="pi pi-times" className=" outline-btn" onClick={hideDialog} />
            <Button loading={isLoading} label="Save" icon="pi pi-check" className="primary-btn" onClick={holdTrans} />
        </>
    );

    return (
        <Dialog footer={productDialogFooter} header={'Held Transaction details'} visible={showHeldDialog} className={'p-fluid'} style={{ width: '700px' }} modal onHide={hideDialog}>
            <div className="field">
                <Messages ref={dialogMessage} style={{ width: '100%' }} />

                <DataTable value={cart?.cartItems} dataKey="id" className="mt-1" emptyMessage="No items for sale found." size="small" resizableColumns>
                    <Column field="shopProductName" header="Item" body={nameBodyTemplate} className="text-left" />
                    <Column field="quantity" header="Qty" className="text-center" />
                    <Column field="unitCost" body={unitCostBodyTemplate} header="Cost" className="text-right" />
                    <Column field="totalCost" body={totalCostBodyTemplate} header="Amount" className="text-right" />
                </DataTable>

                <div className="px-2">
                    <div className="flex justify-content-between mt-2">
                        <span className="font-semibold">Total</span>
                        <span className="text-sm font-normal">
                            {selectedCurrency} <span className="text-lg font-semibold">{formatNumberWithCommas(cart?.totalAmount)}</span>
                        </span>
                    </div>
                    <div className="flex justify-content-between mt-4">
                        <span className="font-semibold">Received</span>
                        <span className="text-sm font-normal">
                            {selectedCurrency} <span className="text-lg font-semibold">{formatNumberWithCommas(cart?.receivedAmount)}</span>
                        </span>
                    </div>
                    <div className="flex justify-content-between mt-2">
                        <span className="font-semibold">Balance</span>
                        <span className="text-sm font-normal">
                            {selectedCurrency} <span className="text-lg font-semibold">{formatNumberWithCommas(cart?.balanceGivenOut)}</span>
                        </span>
                    </div>
                </div>

                <div className="mt-3">
                    <h6>Held Client Details</h6>

                    <div className="formgrid grid mt-3">
                        <>
                            <div className="field col">
                                <label htmlFor="qty">Client name</label>
                                <InputText value={clientName} style={{ color: '#000' }} onChange={(e) => setClientName(e.target.value)} />
                            </div>
                            <div className="field col ">
                                <label>Phone number (optional)</label>
                                <PhoneInput enableSearch id="primaryPhoneNumber" value={clientNumber} onChange={(e) => setClientNumber(e)} country={'ug'} countryCodeEditable={false} autoFormat />
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default HeldModeForm;
