import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import React from 'react';

function FilterComponent({ children, onFilter, onClear = () => {} }) {
    return (
        <div className="py-2 -mt-3">
            <Accordion>
                <AccordionTab className="p-fluid" header="Filter">
                    {children}

                    {children && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                onClick={onClear}
                                label="Clear"
                                icon="pi pi-times"
                                className="p-button-text "
                                style={{
                                    color: 'black',
                                    border: '1px solid black',
                                    margin: '5px',
                                    width: 'fit-content'
                                }}
                            />
                            <Button
                                onClick={() => onFilter()}
                                label="Filter"
                                icon="pi pi-check"
                                className="p-button-text"
                                style={{
                                    color: '#FFEB3B',
                                    border: '1px solid black',
                                    backgroundColor: 'black',
                                    margin: '5px',
                                    width: 'fit-content'
                                }}
                            />
                        </div>
                    )}
                </AccordionTab>
            </Accordion>
        </div>
    );
}

export default FilterComponent;
