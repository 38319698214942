import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { genderOptions, months } from '../../constants/Constants';

export default function BioDataComponent({ onBackPress, onNext, bioData }) {
    const [month, setMonth] = useState(bioData?.month || null);

    const [year, setYear] = useState(bioData?.year);
    const [day, setDay] = useState(bioData?.day);
    const [dob, setDob] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [gender, setGender] = useState(bioData?.gender);

    const isValidYear = (yearNum) => {
        if (yearNum <= 1934 || yearNum >= 2024 || !/^\d+$/.test(yearNum)) {
            return false;
        } else {
            return true;
        }
    };

    const validateDay = (dayNum) => {
        if (!/^\d+$/.test(dayNum) || dayNum >= 31) {
            return false;
        }

        return true;
    };

    const handleBioValidation = () => {
        setError(null);
        let isValidForm = true;
        let data = {
            gender,
            year,
            day,
            month
        };
        setSubmitted(true);
        if (!isValidYear(year)) {
            setError('please enter a valid year');
            isValidForm = false;
        }

        if (!validateDay(day)) {
            setError('please enter a valid day');
            isValidForm = false;
        }

        if (!month) {
            setError('please choose a month');
            isValidForm = false;
        }

        if (!gender) {
            isValidForm = false;
        }
        if (isValidForm) {
            setDob(null);
            onNext(data);
        }
    };

    return (
        <>
            <div className="words-div">
                <h1>Bio data</h1>
                <p>Enter your birthday and gender.</p>
            </div>
            <div className="inputs-div">
                <div className="dob-input-div">
                    <div className="input-div">
                        <label>Month</label>
                        <Dropdown
                            //
                            value={month}
                            optionLabel="name"
                            optionValue="name"
                            onChange={(e) => setMonth(e.value)}
                            options={months}
                            placeholder="Month"
                            className={classNames({
                                'error-input-drop': submitted === true && !month,
                                'drop-down month': true
                            })}
                        />
                    </div>
                    <div className="input-div respons">
                        <label>Day</label>
                        <input
                            value={day}
                            onFocus={() => setSubmitted(false)}
                            htmlFor="day"
                            //
                            className={classNames({
                                'error-input': submitted === true && !validateDay(day)
                            })}
                            onChange={(e) => setDay(e.target.value)}
                            type="text"
                            placeholder="01"
                        />
                    </div>
                    <div className="input-div respons">
                        <label>Year</label>
                        <input
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            type="text"
                            placeholder="2000"
                            className={classNames({
                                'error-input': submitted === true && !isValidYear(year)
                            })}
                        ></input>
                    </div>
                    {error && <label id="error-date">{error}</label>}
                </div>
                <div className="input-div">
                    <label>Gender</label>
                    <Dropdown
                        value={gender}
                        onChange={(e) => setGender(e.value)}
                        options={genderOptions}
                        className={classNames({
                            'error-input-drop': submitted === true && !gender,
                            'genderDr drop-down': true
                        })}
                        placeholder="Gender"
                        optionLabel="name"
                    />
                    {submitted && !gender && <label id="error">{'Please choose a gender'}</label>}
                </div>
                <div>
                    <Button onClick={onBackPress} className="cancel" label="Back" />

                    <Button onClick={handleBioValidation} className="send" label="Next" />
                </div>
            </div>
        </>
    );
}
