import { Dialog } from 'primereact/dialog';
import React, { useState, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { SUBSCRIPTION_PAYMENT_ENDPOINT } from 'utils/EndpointsUtils';
import { BaseApiService } from 'utils/BaseApiService';
import { showErrorMessage } from 'constants/ErrorMessages';
import { convertToServerDate, hasNull } from 'utils/Utils';
import FormFooter from 'components/FormFooter';
import { InputNumber } from 'primereact/inputnumber';
import { subscriptionPaymentMethods } from 'constants/Constants';

function SubscriptionPaymentForm({ isVisible = false, shops = [], toast, setShowForm, refreshTable }) {
    const [isLoading, setLoading] = useState(false);
    const [selectedShop, setSelectedShop] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [amount, setAmount] = useState(null);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paymentMode, setPaymentMode] = useState(subscriptionPaymentMethods[0]);

    const dialogMessage = useRef();

    const clearForm = () => {
        setSelectedShop(null);
        setAmount(0);
        setPaymentDate(new Date());
        setSubmitted(false);
    };

    const hideDialog = () => {
        clearForm();
        setShowForm();
    };

    const onSave = async () => {
        setSubmitted(true);
        const payload = {
            amount: amount,
            shopId: selectedShop?.id,
            paymentDate: convertToServerDate(paymentDate),
            paymentMode: paymentMode?.name
        };

        if (!hasNull(payload)) {
            setLoading(true);
            await new BaseApiService(SUBSCRIPTION_PAYMENT_ENDPOINT)
                .saveRequestWithJsonResponse(payload, false)
                .then((resp) => {
                    hideDialog();
                    refreshTable();
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Payment details saved',
                        life: 3000
                    });
                    setSubmitted(false);
                })
                .catch((e) => {
                    showErrorMessage(dialogMessage, e?.message);
                    setLoading(false);
                    setSubmitted(false);
                });
        }
    };

    return (
        <Dialog
            footer={() => <FormFooter isLoading={isLoading} onClickRight={onSave} onClickLeft={hideDialog} />}
            header={'Subscription Payment'}
            visible={isVisible}
            className="p-fluid"
            //
            // style={{ minWidth: '600px' }}
            modal
            onHide={hideDialog}
        >
            <div className="">
                <Messages ref={dialogMessage} style={{ width: '100%' }} />

                <div className="field">
                    <label>Shop</label>

                    <Dropdown
                        //
                        value={selectedShop}
                        options={shops}
                        onChange={(e) => setSelectedShop(e.target.value)}
                        optionLabel={'name'}
                        filter
                        filterBy="name"
                        placeholder="Select shop"
                    />

                    {submitted && !selectedShop && <small className="p-error">Shop is required.</small>}
                </div>

                <div className="formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label>Payment mode</label>

                        <Dropdown
                            //
                            value={paymentMode}
                            options={subscriptionPaymentMethods}
                            onChange={(e) => setPaymentMode(e.target.value)}
                            optionLabel={'name'}
                            filter
                            filterBy="name"
                            placeholder="Select payment mode"
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label>Amount</label>
                        <InputNumber value={amount} onChange={(e) => setAmount(e.value)} inputClassName="text-right" />
                        {submitted && !amount && <small className="p-error">Amount is required.</small>}
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default SubscriptionPaymentForm;
