import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { BaseApiService } from '../../utils/BaseApiService';
import { Messages } from 'primereact/messages';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { roleTypes } from '../../constants/Constants';
import { Checkbox } from 'primereact/checkbox';

function EditAttendantRoles({ selectedRecord = null, roleDialog = false, setRoleDialog, onComplete = () => {} }) {
    const [permissions, setPermisions] = useState([]);
    const [rolePermissions, setrolePermissions] = useState([]);
    const dialogMessage = useRef(null);

    const getPermissions = async () => {
        await new BaseApiService('/users/roles')
            .getRequestWithJsonResponse({
                offset: 0,
                limit: 20,
                type: roleTypes[1].value
            })
            .then((response) => {
                setPermisions(response.records);
            });
    };

    const populateForm = () => {
        clearForm();
        setrolePermissions(selectedRecord?.roles);
    };

    const handleCheck = (isChecked, permission) => {
        if (isChecked === true) {
            const updatedList = rolePermissions.filter((item) => item.id !== permission.id);
            setrolePermissions(updatedList);
        } else {
            setrolePermissions((prev) => [...prev, permission]);
        }
    };

    const clearForm = () => {};

    const hideDialog = () => {
        setRoleDialog(false);
        clearForm();
    };

    useEffect(() => {
        if (selectedRecord) {
            populateForm();
        }
    }, [selectedRecord]);

    useEffect(() => {
        getPermissions();
    }, []);

    const saveInfo = async () => {
        const payload = {
            // shopOwnerId: 0,
            roleIds: rolePermissions.map((i) => i?.id)
        };

        await new BaseApiService(`/shops/attendants/${selectedRecord?.id}/attach-roles`)
            .saveRequestWithJsonResponse(payload, false)
            .then((response) => {
                hideDialog();
                onComplete();
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error?.message);
            });
    };

    const footer = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="primary-btn" onClick={saveInfo} />
        </>
    );

    return (
        <Dialog visible={roleDialog} footer={footer} style={{ width: '700px' }} header="Shop user roles" modal className="p-fluid" onHide={hideDialog}>
            <Messages ref={dialogMessage} style={{ width: '100%' }} />

            <div className="flex flex-wrap gap-3 mt-3 mb-2">
                {permissions.map((item, i) => {
                    const isChecked = rolePermissions?.some((perm) => perm?.id === item.id);

                    return (
                        <div className="flex gap-2" key={i}>
                            <Checkbox onChange={() => handleCheck(isChecked, item)} checked={isChecked ? isChecked : false}></Checkbox>
                            <p>{item.name}</p>
                        </div>
                    );
                })}
            </div>
        </Dialog>
    );
}

export default EditAttendantRoles;
