import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { CURRENCIES_END_POINT, LOOK_UPS_ENDPOINT, SHOPS_ENDPOINT, SHOP_OWNERS_ENDPOINT } from '../../utils/EndpointsUtils';
import FormFooter from '../FormFooter';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { hasNull } from '../../utils/Utils';
import { showErrorMessage } from '../../constants/ErrorMessages';
import ChipButton from '../ChipButton';
import { InputSwitch } from 'primereact/inputswitch';

const ShopDetailsForm = ({ selectedShop, visible, hideDialog, onComplete }) => {
    const [shop, setShop] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState(null);
    const [shopOwners, setShopOwners] = useState(null);
    const [selectedShopOwner, setSelectedShopOwner] = useState(null);
    const [shopCategories, setShopCategories] = useState([]);
    const [selectedCategory, setSelectdCategory] = useState(null);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [supportsCredit, setSupportsCredit] = useState(false);
    const [collectClientInfo, setCollectClientInfo] = useState(false);

    const isAdmin = UserSessionUtils.getSuperAdmin();
    const dialogMessage = useRef();

    const fetchCurrencies = async () => {
        await new BaseApiService(CURRENCIES_END_POINT)
            .getRequestWithJsonResponse({ offset: 0, limit: 0 })
            .then(async (response) => {
                setCurrencies(response.records);
            })
            .catch((error) => {});
    };

    const fetchShopCategories = async () => {
        const searchParameters = {
            offset: 0,
            limit: 0,
            commaSeparatedTypeIds: [6]
        };
        await new BaseApiService(LOOK_UPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShopCategories(response?.records);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchShopOwners = async () => {
        await new BaseApiService(SHOP_OWNERS_ENDPOINT)
            .getRequestWithJsonResponse({ offset: 0, limit: 0 })
            .then(async (response) => {
                setShopOwners(response.records);
            })
            .catch((error) => {});
    };

    const populateForm = () => {
        if (selectedShop) {
            clearForm();
            setEdit(true);
            setShop(selectedShop);
            setSelectdCategory(selectedShop?.categoryId);
            setDefaultCurrency(currencies.find((obj) => obj?.id === selectedShop?.currencyId));
            setCollectClientInfo(selectedShop?.captureClientDetailsOnAllSales || false);
            setSupportsCredit(selectedShop?.supportsCreditSales || false);
            setSelectedShopOwner(selectedShop?.ownerId);
        }
    };

    const hide = () => {
        clearForm();
        hideDialog();
    };

    const clearForm = () => {
        setShop(null);
        setDefaultCurrency(null);
        setSelectdCategory(null);
        setEdit(false);
        setLoading(false);
        setSelectedShopOwner(null);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _shop = { ...shop };
        _shop[`${name}`] = val;

        setShop(_shop);
    };

    useEffect(() => {
        fetchCurrencies();
        fetchShopCategories();
        fetchShopOwners();
    }, []);

    useEffect(() => {
        populateForm();
    }, [selectedShop]);

    const saveShop = async () => {
        setSubmitted(true);
        const apiUrl = edit === true ? `${SHOPS_ENDPOINT}/${shop.id}` : SHOPS_ENDPOINT;

        const payload = {
            name: shop?.name,
            locationLatitude: shop?.locationLatitude,
            locationLongitude: shop?.locationLongitude,
            physicalLocation: shop?.physicalLocation,
            registrationNumber: shop?.registrationNumber,
            initialCapital: shop?.initialCapital,
            currencyId: defaultCurrency?.id,
            categoryId: selectedCategory,
            ownerId: selectedShopOwner,
            captureClientDetailsOnAllSales: collectClientInfo,
            supportsCreditSales: supportsCredit
        };

        console.log(payload);
        if (hasNull(payload) === false) {
            setLoading(true);
            await new BaseApiService(apiUrl)
                .saveRequestWithJsonResponse(payload, edit)
                .then((response) => {
                    hide();
                    onComplete();
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error.message);
                    setLoading(false);
                });
        }
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: '700px' }}
            header="Shop Details"
            modal
            //
            className="p-fluid"
            footer={() => <FormFooter onClickLeft={hide} onClickRight={saveShop} isLoading={loading} />}
            onHide={hide}
        >
            <Messages ref={dialogMessage} style={{ width: '100%' }} />
            {isAdmin && (
                <div className="field" style={{ marginTop: '10px' }}>
                    <label htmlFor="shopOwner">Shop Owner</label>
                    <Dropdown
                        id="shopOwner"
                        value={selectedShopOwner}
                        options={shopOwners}
                        onChange={(e) => {
                            setSelectedShopOwner(e.target.value);
                            console.log(e.target.value);
                        }}
                        optionLabel={(option) => option.firstName + ' ' + option.lastName}
                        filter
                        optionValue="id"
                        placeholder="Select a Shop owner"
                    />
                    {submitted && !selectedShopOwner && <small className="p-invalid">Shop owner is required.</small>}
                </div>
            )}
            <div className="formgrid grid">
                <div className="field col-6 md:col-6">
                    <label htmlFor="name">Shop Name</label>
                    <InputText id="name" value={shop?.name || ''} onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': submitted && !shop?.name })} />
                    {submitted && !shop?.name && <small className="p-invalid">Shop name is required.</small>}
                </div>

                <div className="field col-6 md:col-6">
                    <label htmlFor="name">Shop category</label>
                    <Dropdown
                        //
                        value={selectedCategory}
                        options={shopCategories}
                        onChange={(e) => {
                            setSelectdCategory(e.value);
                        }}
                        optionLabel={'value'}
                        optionValue="id"
                        filter
                        placeholder="Select a category"
                    />
                    {submitted && !selectedCategory && <small className="p-invalid">Shop category is required.</small>}
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col-6 md:col-6">
                    <label htmlFor="physicalLocation">Physical address</label>
                    <InputText
                        id="physicalLocation"
                        value={shop?.physicalLocation || ''}
                        onChange={(e) => onInputChange(e, 'physicalLocation')}
                        required
                        className={classNames({
                            'p-invalid': submitted && !shop?.physicalLocation
                        })}
                    />
                    {submitted && !shop?.physicalLocation && <small className="p-invalid">Physical location name is required.</small>}
                </div>
                <div className="field col-6 md:col-6">
                    <label htmlFor="registrationNumber">Registration Number</label>
                    <InputText
                        id="registrationNumber"
                        value={shop?.registrationNumber || ''}
                        onChange={(e) => onInputChange(e, 'registrationNumber')}
                        required
                        className={classNames({
                            'p-invalid': submitted && !shop?.registrationNumber
                        })}
                    />
                    {submitted && !shop?.registrationNumber && <small className="p-invalid">Registration Number is required.</small>}
                </div>
            </div>
            <div className="formgrid grid">
                <div className="field col-6 md:col-6">
                    <label htmlFor="locationLatitude">Location latitude</label>
                    <InputText
                        id="locationLatitude"
                        value={shop?.locationLatitude || ''}
                        onChange={(e) => onInputChange(e, 'locationLatitude')}
                        required
                        className={classNames({
                            'p-invalid': submitted && !shop?.locationLatitude
                        })}
                    />
                    {submitted && !shop?.locationLatitude && <small className="p-invalid">Location latitude is required.</small>}
                </div>
                <div className="field col-6 md:col-6">
                    <label htmlFor="locationLongitude">Location longitude</label>
                    <InputText
                        id="locationLongitude"
                        value={shop?.locationLongitude || ''}
                        onChange={(e) => onInputChange(e, 'locationLongitude')}
                        required
                        className={classNames({
                            'p-invalid': submitted && !shop?.locationLongitude
                        })}
                    />
                    {submitted && !shop?.locationLongitude && <small className="p-invalid">Location longitude is required.</small>}
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col-6 md:col-6">
                    <label htmlFor="locationLatitude">Default Currency</label>
                    <Dropdown
                        options={currencies}
                        value={defaultCurrency}
                        optionLabel="name"
                        onChange={(e) => setDefaultCurrency(e.target.value)}
                        placeholder="Select a Currency"
                        className={classNames({
                            'p-invalid': submitted && !defaultCurrency
                        })}
                        checkmark={true}
                        highlightOnSelect={false}
                        filter
                    />
                    {submitted && !shop?.locationLatitude && <small className="p-invalid">Default currency is required.</small>}
                </div>
                <div className="field col-6 md:col-6">
                    <label htmlFor="initialCapital">Initial Capital</label>
                    <InputNumber
                        id="initialCapital"
                        value={shop?.initialCapital || ''}
                        onValueChange={(e) => onInputChange(e, 'initialCapital')}
                        required
                        className={classNames({
                            'p-invalid': submitted && !shop?.initialCapital
                        })}
                    />
                    {submitted && !shop?.initialCapital && <small className="p-invalid">Initial capital is required.</small>}
                </div>
            </div>

            <div className="field-col">
                <div className="flex flex-wrap gap-3 align-items-center mb-2">
                    <label className="text-lg">Support credit</label>
                    <InputSwitch checked={supportsCredit} onChange={(e) => setSupportsCredit(!supportsCredit)} />
                </div>

                <div className="flex flex-wrap gap-3">
                    <label className="text-lg">Collect Client information on sales</label>

                    <InputSwitch checked={collectClientInfo} onChange={(e) => setCollectClientInfo(!collectClientInfo)} />
                </div>
            </div>
        </Dialog>
    );
};

export default ShopDetailsForm;
