import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React from 'react';

function ChipButton({ isSelected = false, label = '', onClick }) {
    return (
        <Button
            //
            className={classNames({
                'outline-btn border-round': !isSelected,
                'border-round': isSelected
            })}
            key={label}
            onClick={onClick}
            label={label}
            style={{ width: 'fit-content' }}
        />
    );
}

export default ChipButton;
