import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { BaseApiService } from '../../utils/BaseApiService';
import { convertToServerDate, hasNull } from '../../utils/Utils';
import { Messages } from 'primereact/messages';
import { showErrorMessage } from '../../constants/ErrorMessages';

function StockEntryForm({ selectedRecord, stockEntryDialog, setStockEntryDialog, onSave, shops = [] }) {
    let packageOptions = [
        { value: 'Packed', type: true },
        { value: 'Unpacked', type: false }
    ];

    const [submitted, setSubmitted] = useState(false);
    const [selectedShop, setSelectedShop] = useState(null);
    const [selectedManufacturerId, setSelectedManufacturerId] = useState(null);
    const [products, setProducts] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState(new Date());

    const [isLoading, setIsLoading] = useState(false);

    const [isPackedProduct, setIsPackedProduct] = useState(null);
    const [batchNumber, setBatchNumber] = useState(null);
    const [remarks, setRemarks] = useState('');

    const [unpackedPurchasedQuantity, setUnpackedPurchasedQuantity] = useState(null);
    const [packedPurchasedQuantity, setPackedPurchasedQuantity] = useState(null);
    const [purchasePrice, setPurchasePrice] = useState(null);

    const [edit, setEdit] = useState(false);

    const dialogMessage = useRef();
    const toast = useRef(null);
    const isShopAttendant = UserSessionUtils.getShopAttendant();
    const attendantShopId = UserSessionUtils.getShopId();

    const populateForm = () => {
        clearForm();
        if (selectedRecord !== null && selectedRecord !== undefined) {
            setEdit(true);
            setSelectedProduct(selectedRecord.productId ? products?.find((obj) => obj.id === selectedRecord.productId) : null);
            setIsPackedProduct(packageOptions.find((obj) => obj.type !== selectedRecord?.unpackedStock).value);
            setExpiryDate(new Date(selectedRecord?.expiryDate));
            setSelectedSupplier(selectedRecord.supplierId ? suppliers?.find((obj) => obj.id === selectedRecord.supplierId) : null);
            setSelectedShop(selectedRecord.shopId ? shops?.find((obj) => obj.id === selectedRecord.shopId) : null);
            setPurchaseDate(new Date(selectedRecord?.stockedOnDate));
            setSelectedManufacturerId(selectedRecord?.manufacturerId);
            setPurchasePrice(selectedRecord.purchasePrice);
            setUnpackedPurchasedQuantity(selectedRecord?.unpackedQuantity);
            setPackedPurchasedQuantity(selectedRecord?.packedQuantity);
            setBatchNumber(selectedRecord?.batchNumber);
            setRemarks(selectedRecord?.remarks);
        }
    };

    const clearForm = () => {
        setSelectedProduct(null);
        setIsPackedProduct(null);
        setExpiryDate(null);
        setSelectedSupplier(null);
        setPurchaseDate(new Date());
        setSelectedManufacturerId(null);
        setPurchasePrice(null);
        setUnpackedPurchasedQuantity(null);
        setPackedPurchasedQuantity(null);
        setRemarks(null);
        setBatchNumber(null);
    };

    const fetchSuppliers = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/suppliers')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setSuppliers(response.records);
            })
            .catch((error) => {});
    };

    const fetchProducts = async () => {
        let searchParameters = { offset: 0, limit: 100000 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }

        if (selectedShop) {
            searchParameters.shopId = selectedShop?.id;
        }

        new BaseApiService('/shop-products')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setProducts(response.records);
            })
            .catch((error) => {});
    };

    const hideDialog = () => {
        setSubmitted(false);
        setStockEntryDialog(false);
        clearForm();
    };

    const saveStockEntry = () => {
        setSubmitted(true);
        setIsLoading(true);

        const apiUrl = edit && edit === true ? '/stock-entries/' + selectedRecord.id : '/stock-entries';

        let payload;

        const isPacked = isPackedProduct && isPackedProduct === 'Packed';
        let idToUse = isShopAttendant ? attendantShopId : selectedShop?.id;

        let constantFields = {
            shopId: idToUse,
            productId: selectedProduct?.id,
            supplierId: selectedSupplier?.id,
            id: 0,
            stockedOnDate: convertToServerDate(purchaseDate),
            manufacturerId: selectedManufacturerId,
            remarks: remarks || '',
            expiryDate: convertToServerDate(expiryDate),
            batchNumber: batchNumber,
            productName: selectedProduct?.productName,
            purchasePrice: purchasePrice
        };

        //setting payload basing on item package type
        if (isPacked === true) {
            payload = {
                ...constantFields,
                packedPurchasedQuantity: packedPurchasedQuantity,
                unpackedPurchase: isPackedProduct === 'Unpacked'
            };
        }

        if (isPacked === false) {
            payload = {
                ...constantFields,
                unpackedPurchase: isPackedProduct === 'Unpacked',
                unpackedPurchasedQuantity: unpackedPurchasedQuantity
            };
        }

        let isValidPayload = payload !== undefined && hasNull(payload) === false;

        if (isValidPayload === false) {
            setIsLoading(false); //removing loader if form is invalid
        }
        if (isValidPayload === true) {
            new BaseApiService(apiUrl)
                .saveRequestWithJsonResponse(payload, edit)
                .then((response) => {
                    onSave();
                    hideDialog();
                    setEdit(false);
                    setIsLoading(false);
                    setSubmitted(false);
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error.message);
                    setSubmitted(false);
                    setIsLoading(false);
                });
        }
    };

    const onProductChange = (e) => {
        const { manufacturerId } = e.target.value;
        setSelectedManufacturerId(manufacturerId);
        setSelectedProduct(e.target.value);
    };

    const getUnitPrice = () => {
        let price = purchasePrice / selectedProduct?.packageQuantity;
        return price;
    };

    const shopAttendantDialogFooter = (
        <>
            <Button loading={isLoading} label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideDialog} />
            <Button loading={isLoading} label="Save" icon="pi pi-check" className="primary-btn" onClick={saveStockEntry} />
        </>
    );

    useEffect(() => {
        populateForm();
    }, [selectedRecord]);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [selectedShop]);

    return (
        <Dialog visible={stockEntryDialog} style={{ width: '700px' }} header="Stock Information" modal className="p-fluid" footer={shopAttendantDialogFooter} onHide={hideDialog}>
            <Messages ref={dialogMessage} style={{ width: '100%' }} />
            {!isShopAttendant && (
                <div className="field" style={{ marginTop: '10px' }}>
                    <label htmlFor="shop">Shop</label>
                    <Dropdown
                        id="shop"
                        value={selectedShop}
                        options={shops}
                        onChange={(e) => setSelectedShop(e.target.value)}
                        optionLabel={'name'}
                        filter
                        filterBy="name"
                        placeholder="Select Shop"
                        className={classNames({ 'p-invalid': submitted && !selectedShop })}
                    />
                    {submitted && !selectedShop && <small className="p-error">Shop is required.</small>}
                </div>
            )}

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="supplier">Supplier</label>
                    <Dropdown
                        id="supplier"
                        value={selectedSupplier}
                        options={suppliers}
                        onChange={(e) => setSelectedSupplier(e.target.value)}
                        filter
                        filterBy="companyOrBusinessName"
                        optionLabel="companyOrBusinessName"
                        placeholder="Select a supplier"
                        className={classNames({
                            'p-invalid': submitted && !selectedSupplier
                        })}
                    />
                    {submitted && !selectedSupplier && <small className="p-error">Supplier is required.</small>}
                </div>
            </div>
            <div className="formgrid grid">
                <div className="field col-12 md:col-6">
                    <label htmlFor="product">Product</label>
                    <Dropdown
                        id="product"
                        value={selectedProduct}
                        options={products}
                        onChange={onProductChange}
                        optionLabel="productName"
                        filter
                        filterBy="productName"
                        placeholder="Select a product"
                        className={classNames({
                            'p-invalid': submitted && !selectedProduct
                        })}
                    />
                    {submitted && !selectedProduct && <small className="p-error">Product is required .</small>}
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="product">Package Type</label>
                    <Dropdown
                        disabled={selectedProduct === null}
                        id="productType"
                        value={isPackedProduct}
                        options={packageOptions}
                        onChange={(e) => {
                            setIsPackedProduct(e.target.value);
                        }}
                        optionLabel={'value'}
                        placeholder="Select package type"
                        className={classNames({
                            'p-invalid': submitted && !isPackedProduct
                        })}
                    />
                    {submitted && !selectedProduct && <small className="p-error">Package type is required .</small>}
                </div>
            </div>

            <div className="formgrid grid mt-2" style={{ display: isPackedProduct === 'Packed' ? 'flex' : 'none' }}>
                <div className="field col-6 md:col-6">
                    <label htmlFor="purchasedQuantity">{`Packed Qty (${selectedProduct?.packageUnitName || ''})`}</label>
                    <InputNumber
                        minFractionDigits={1}
                        id="purchasedQuantity"
                        value={packedPurchasedQuantity}
                        onValueChange={(e) => setPackedPurchasedQuantity(e.value)}
                        className={classNames({
                            'p-invalid': submitted && !packedPurchasedQuantity
                        })}
                    />
                    {submitted && !packedPurchasedQuantity && <small className="p-error">Purchased quantity is required .</small>}
                </div>

                <div className="field col-6 md:col-6">
                    <label htmlFor="purchasePrice">Purchase price {selectedProduct?.currency && `(${selectedProduct?.currency})`}</label>
                    <InputNumber id="purchasePrice" value={purchasePrice} onValueChange={(e) => setPurchasePrice(e.value)} className={classNames({ 'p-invalid': submitted && !purchasePrice })} />
                    {submitted && !purchasePrice && <small className="p-error">Purchase price is required .</small>}
                </div>
            </div>

            <div className="formgrid grid mt-2" style={{ display: isPackedProduct === 'Unpacked' ? 'flex' : 'none' }}>
                <div className="field col-6 md:col-6">
                    <label htmlFor="unpackedQuantity">Unpacked quantity</label>
                    <InputNumber
                        minFractionDigits={1}
                        id="unpackedQuantity"
                        value={unpackedPurchasedQuantity}
                        onValueChange={(e) => setUnpackedPurchasedQuantity(e.value)}
                        className={classNames({
                            'p-invalid': submitted && !unpackedPurchasedQuantity
                        })}
                    />
                    {submitted && !unpackedPurchasedQuantity && <small className="p-error">Unpacked quantity is required .</small>}
                </div>

                <div className="field col-6 md:col-6">
                    <label htmlFor="unpackedUnitPrice">Purchase amount {selectedProduct?.currency && `(${selectedProduct?.currency})`}</label>
                    <InputNumber id="unpackedUnitPrice" value={purchasePrice} onValueChange={(e) => setPurchasePrice(e.value)} className={classNames({ 'p-invalid': submitted && !purchasePrice })} />
                    {submitted && !purchasePrice && <small className="p-error">Unpacked unit price is required .</small>}
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col-6 md:col-6">
                    <label htmlFor="batchNumber">Batch no</label>
                    <InputText id="batchNumber" value={batchNumber} onChange={(e) => setBatchNumber(e.target.value)} className={classNames({ 'p-invalid': submitted && !batchNumber })} />
                    {submitted && !batchNumber && <small className="p-error">Batch number is required .</small>}
                </div>

                <div className="field col-6 md:col-6">
                    <label htmlFor="expiryDate">Expiry date</label>
                    <Calendar id="expiryDate" value={expiryDate} onChange={(e) => setExpiryDate(e.value)} className={classNames({ 'p-invalid': submitted && !expiryDate })} dateFormat="dd-M-yy" />
                    {submitted && !expiryDate && <small className="p-error">Expiry date is required .</small>}
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col-6 md:col-6">
                    <label htmlFor="UnitPrice">Unit price {selectedProduct?.currency && `(${selectedProduct?.currency})`}</label>
                    <InputNumber readOnly value={getUnitPrice() || 0} />
                </div>

                <div className="field col-6 md:col-6">
                    <label htmlFor="expiryDate">Purchase date</label>
                    <Calendar id="expiryDate" value={purchaseDate} onChange={(e) => setPurchaseDate(e.value)} className={classNames({ 'p-invalid': submitted && !purchaseDate })} dateFormat="dd-M-yy" />
                    {submitted && !purchaseDate && <small className="p-error">Purchase date is required .</small>}
                </div>
            </div>
            <div className="field mt-4">
                <label htmlFor="remarks">Remarks</label>
                <InputTextarea style={{ height: 100 }} id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
            </div>
        </Dialog>
    );
}

export default StockEntryForm;
